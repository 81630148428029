import React from 'react';
import BinIcon from '../../images/bin_icon.png'
import PlusIcon from '../../images/+_icon.png'
import { TranslatedTexts } from '../Locale/TranslatedTexts';
import Dialog from '../Dialog/Dialog';
import { AdminHandler } from '../AdminHandler/AdminHandler';
import SearchBar from '../SearchBar/SearchBar';
import Checkmark from '../../images/faq_check.png'

class CompanyList extends React.Component {
    companiesSortTags = [
    {title: TranslatedTexts.SortTagName, 
      function: 
        function(elements){ 
          elements.sort(function(a, b){
            if(a.CompanyName < b.CompanyName) { return -1; }
            if(a.CompanyName > b.CompanyName) { return 1; }
            return 0;
        })
      }},
    {title: TranslatedTexts.SortTagCompaniesModelLimit, 
      function: 
        function(elements){ 
          elements.sort(function(a, b){
            return b.ModelLimit - a.ModelLimit;
          })
      }},
    {title: TranslatedTexts.SortTagCompaniesAppAccess, 
      function: 
        function(elements){ 
          elements.sort(function(a, b){
            return (a.AppAccess === b.AppAccess)? 0 : a.AppAccess? -1 : 1;
          })
      }},
    ]

    static defaultProps = {
      onUpdateCompanies: function(){},
      onChangeUserAccess: function(){},
    };

    constructor(props) {
        super(props);

        this.state = {
          dragOver: false,
          users: [], //probably not needed
          companies: [],
          addingCompany: false,
          tempCompanyName: "",
          tempCompanyLimit: 0,
          tempUserLimit: 0,
          tempRapid: false,
          tempAppAccess: false,
          editCompanyLimit: -1,
          editCompanyUserLimit: -1,
          tempModelLimit: "",
          companiesSearchOptions: {sortTag: this.companiesSortTags[0], filterTag: null, inverted: false},
        };
    }

    componentDidMount(){
      this.getCompanies();      
    }  

    componentDidUpdate(prevProps){
        //this.getCompanies();  
    }

    setEditCompanyLimit(idx, newLimit ="")
    {            
      if (newLimit != "")
      {        
        this.state.companies[this.state.editCompanyLimit].ModelLimit = newLimit;        
        AdminHandler.updateCompanyLimit(this.state.companies[this.state.editCompanyLimit].CompanyGuid, newLimit);
      }
      var templimit = "";      
      if (this.state.companies && this.state.companies[idx])
      {        
        templimit = this.state.companies[idx].ModelLimit;
      }        
      this.setState({editCompanyLimit: idx, tempModelLimit: templimit});
    }  

    setEditCompanyUserLimit(idx, newLimit =""){            
      if (newLimit != ""){        
        this.state.companies[this.state.editCompanyUserLimit].UserLimit = newLimit;        
        AdminHandler.updateUserLimit(this.state.companies[this.state.editCompanyUserLimit].CompanyGuid, newLimit);
      }

      var templimit = "";      
      if (this.state.companies && this.state.companies[idx]){        
        templimit = this.state.companies[idx].UserLimit;
      } 

      this.setState({editCompanyUserLimit: idx, tempUserLimit: templimit});
    }  

    getCompanies(callback = function(){}){
        AdminHandler.getAllCompanies("asd")
        .then((res) => {
            res.data.sort((a,b) => {
                return a.CompanyName > b.CompanyName ? 1 : -1;
            });        
            
            if(res.data){
                this.setState({companies: res.data})
            }

            this.props.onUpdateCompanies(res.data);
        })
        .catch((err) =>{})
    }

    getCompanyTable(){
      var elements = [];
      let companiesToRender = this.state.companies;
      const searchOptions = this.state.companiesSearchOptions;
      if(searchOptions){
        if(searchOptions.sortTag){
          // --> sort companies
          searchOptions.sortTag.function(companiesToRender);
          if(searchOptions.inverted){
            companiesToRender.reverse(); 
          }
        }

        if(searchOptions.filterTag){
          // --> filter companies
          companiesToRender = searchOptions.filterTag.function(companiesToRender, searchOptions.filterTag.searchText);
        }
      }
      
      companiesToRender.map((elem, indx) =>(          
          elements.push(
            <tr className='TableRow' onClick={(indx) => this.props.onChangeUserAccess(indx) /*TODO:this.editUser(indx)*/} key={indx}>
                <td>{elem.CompanyName}</td>
                {this.state.editCompanyLimit != indx && 
                  <td onClick={()=> this.setEditCompanyLimit(indx)}><label >{elem.ModelLimit}</label></td>
                }
                {this.state.editCompanyLimit == indx &&
                  <td>
                    <input 
                      type="text" 
                      autoFocus 
                      onKeyDown={(e) => {if(e.keyCode == 13)this.setEditCompanyLimit(-1,this.state.tempModelLimit)}} 
                      onBlur={() => this.setEditCompanyLimit(-1, this.state.tempModelLimit)}
                      value={this.state.tempModelLimit}
                      onChange={(e) => this.setState({tempModelLimit: e.target.value})}                      
                      />
                  </td>
                }
                {this.state.editCompanyUserLimit != indx && 
                  <td onClick={()=> this.setEditCompanyUserLimit(indx)}><label>{elem.UserLimit ? elem.UserLimit : "?"}</label></td>
                }
                {this.state.editCompanyUserLimit == indx &&
                  <td>
                    <input 
                      type="text" 
                      autoFocus 
                      onKeyDown={(e) => {if(e.keyCode == 13)this.setEditCompanyUserLimit(-1,this.state.tempUserLimit)}} 
                      onBlur={() => this.setEditCompanyUserLimit(-1, this.state.tempUserLimit)}
                      value={this.state.tempUserLimit}
                      onChange={(e) => this.setState({tempUserLimit: e.target.value})}                      
                      />
                  </td>
                }
                <td>
                  <label className="switch">
                      <input onChange={(e) => this.updateAppAccess(elem.CompanyGuid, e.target.checked)} checked={elem["AppAccess"]} type="checkbox"></input>
                      <span className="slider round"></span>
                  </label>
                </td>
                <td>
                  <label className="switch">
                      <input onChange={(e) => this.updateRapidAccess(elem.CompanyGuid, e.target.checked)} checked={elem["PipelineType"]} type="checkbox"></input>
                      <span className="slider round"></span>
                  </label>
                </td>
              <td>
              <button title={TranslatedTexts.RemCompany} className="armodelviewer_button" style={{fontSize: '0.8em', marginRight: '1em'}} onClick={() => this.remCompany(elem.CompanyGuid)}><img src={BinIcon}/></button></td>
            </tr>)
        ));

        return elements;        
    }

    updateAppAccess(companyId, newAccess){
      var newCompanies = this.state.companies;
      newCompanies.find((e) => e.CompanyGuid == companyId)["AppAccess"] = newAccess;
      this.setState({companies: newCompanies});
      AdminHandler.updateCompanyAppAccess(companyId, newAccess)
      .then((res) => {
        this.getCompanies();
      })
      .catch((err) =>{})      
    }

    updateRapidAccess(companyId, newAccess){
      var newCompanies = this.state.companies;
      newCompanies.find((e) => e.CompanyGuid == companyId)["Rapid"] = newAccess;
      this.setState({companies: newCompanies});
      AdminHandler.updateCompanyRapidAccess(companyId, newAccess)
      .then((res) => {
        this.getCompanies();
      })
      .catch((err) =>{})      
    }

    resetInputFields(){
      this.setState({addingCompany: false, tempCompanyName: "", tempCompanyLimit: 0, tempUserLimit: 0, tempRapid: false, tempAppAccess: false});
    }

    remCompany(guid){
        const self = this;
        var companyName = this.state.companies.filter(company => company.CompanyGuid == guid)[0].CompanyName;
        Dialog.Alert({
            title: TranslatedTexts.RemCompany + " " + companyName,
            description: TranslatedTexts.RemCompanySure,
            options: [
                {label: TranslatedTexts.Delete, className:"dangerConfirm"},
                {label: TranslatedTexts.Cancel, className:""},
            ],
            panelOptions: {className: "messebau"}
            }).then((optionChosen) => {        
            if (optionChosen == 0) {
                AdminHandler.remCompany("userid", guid)
                .then((res) => {
                self.setState({addingCompany: false});
                self.getCompanies();
                })
                .catch((err) =>{})     
            }
        });       
    }

    addCompany(){
      const self = this;
      const st = this.state;
      AdminHandler.addCompany("userid", st.tempCompanyName, st.tempCompanyLimit, st.tempUserLimit, st.tempAppAccess, st.tempRapid).then((res) => {
        self.resetInputFields();
        self.getCompanies();
      })
      .catch((err) =>{})
    }

    render() {
      return (
        <div className='user_files_container'>
            <div className='user_files_list_header'>
                <div className='user_files_list_header_left'>
                <div id='user_list_circle' className='user_list_circle'/>
                  <label id='Models'>{TranslatedTexts.Companies}</label>                      
                </div>
            </div>
            <div className='user_files_list_container'>
                <SearchBar
                onNewSearchOptions={(options) => {this.setState({companiesSearchOptions: options})}}
                sortTags={this.companiesSortTags}
                filterTags={[
                    {title: TranslatedTexts.FilterTagName,  
                    function: 
                        function(elements, filterText){ 
                        return elements.filter((elem) => {
                            if(filterText == null || filterText == ""){
                            return true; //if there is nothing to filter against
                            }
                            return elem && elem.CompanyName && filterText && elem.CompanyName.toLowerCase().indexOf(filterText.toLowerCase()) >= 0;
                        })
                    }},
                ]}
                />
                <div className='user_files_list'>
                <table style={{color: "rgba(255, 255, 255, 0.87)", width: "80vw"}}>
                    <thead style={{textAlign: "left"}}>
                    <tr>
                        <th style={{color: "rgba(255, 255, 255, 0.5)"}}>Name</th>
                        <th style={{color: "rgba(255, 255, 255, 0.5)"}}>{TranslatedTexts.CompaniesModelLimit}</th>
                        <th style={{color: "rgba(255, 255, 255, 0.5)"}}>{TranslatedTexts.CompaniesCoworkersLimit}</th>
                        <th style={{color: "rgba(255, 255, 255, 0.5)"}}>App</th>
                        <th style={{color: "rgba(255, 255, 255, 0.5)"}}>Rapid</th>
                    <th>
                    {!this.state.addingCompany && <button className="armodelviewer_button" title={TranslatedTexts.AddCompany} style={{fontSize: '0.8em', marginRight: '1em'}} onClick={() => this.setState({addingCompany: true})}><img src={PlusIcon}></img></button>}
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.addingCompany &&
                    <tr>
                      <td>
                        <input 
                          type='text'
                          tempCompanyLimit
                          value={this.state.tempCompanyName}
                          onChange={(e) => this.setState({tempCompanyName: e.target.value})}/>
                      </td>
                      <td>
                        <input 
                          type='text'
                          pattern="[1-9]{1}[0-9]{3}"
                          value={this.state.tempCompanyLimit}
                          onChange={(e) => this.setState({tempCompanyLimit: e.target.value})}/>
                      </td>
                      <td>
                        <input 
                          type='text'
                          pattern="[1-9]{1}[0-9]{3}"
                          value={this.state.tempUserLimit}
                          onChange={(e) => this.setState({tempUserLimit: e.target.value})}/>
                      </td>
                      <td>
                        <label className="switch">
                          <input type="checkbox" onChange={(e) => this.setState({tempAppAccess : e.target.value})}></input>
                          <span className="slider round"></span>
                        </label>
                      </td>
                      <td>
                        <label className="switch">
                          <input type="checkbox" onChange={(e) => this.setState({tempRapid : e.target.value})}></input>
                          <span className="slider round"></span>
                        </label>
                      </td>
                      <td>
                        <div style={{display: "flex", flexDirection: "row"}}>
                          <button onClick={() => this.addCompany()} className="armodelviewer_button" title={TranslatedTexts.AddCompany} style={{fontSize: '0.8em', marginRight: '1em'}}><img style={{height: "16px", width: "16px"}} src={Checkmark}/></button><button title={TranslatedTexts.Cancel} onClick={() => this.resetInputFields()} className="armodelviewer_button" style={{fontSize: '0.8em', marginRight: '1em'}}><img style={{height: "16px", width: "16px", transform: "rotate(45deg)"}} src={PlusIcon}/></button>
                        </div>
                      </td>
                    </tr>
                    }
                    {this.getCompanyTable()}
                    </tbody>
                </table>
                </div>
            </div>
        </div>);
    }
}

export default CompanyList;
