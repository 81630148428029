import React from 'react';
import TranslatedComponent from '../Locale/TranslatedComponent';
import FileUploader from '../FilePicker/FilePicker';
import { Uploader } from '../Uploader/Uploader';
import TutorialGraphic_DE from '../../images/Tutorial_DE.png';
import TutorialGraphic_EN from '../../images/Tutorial_EN.png';
import TutorialIcon from '../../images/tutorial_icon.png';
import CopyIcon from '../../images/copyLink_icon.png'
import CloseIcon from '../../images/close_icon.png';
import ModelviewerPopup from '../ModelviewerPopup/ModelviewerPopup';
import { TranslatedTexts } from '../Locale/TranslatedTexts';
import UserList from '../UserList/UserList';
import CompanyList from '../CompanyList/CompanyList';
import FileList from '../FileList/FileList';
import {QRCodeSVG} from 'qrcode.react';
import Dialog from '../Dialog/Dialog';
import { AdminHandler } from '../AdminHandler/AdminHandler';
import './MainPage.less'

class MainPage extends React.Component {
  static defaultProps = {
    realm: null,
    companyID: null,
    fileSpaces: 5,
    appAccess: false,
    isAdmin: false,
    canEdit: false,
  };

  closeNoticeTimeout = null;

  constructor(props) {
      super(props);
      this.state = {
        fileSpaces: this.props.fileSpaces,
        appAccess: this.props.appAccess,
        userFilesData: [],
        modelviewerModel: null,
        activeQrCode: null,
        users: [],
        tutorialOpen: false,
        noticeSustain: 1500,
        showNotice: false,
        showQRCodeOverlay: null,
        updateFiles: false,
        companies: [],
        seed: Math.random(),
        modelInitialOpen: false,
        modelWarningSize: 10485760, //bytes //=10mb
      };

  }

  componentDidMount(){
    this.getUserFiles();
  }  

  componentDidUpdate(prevProps, prevState){
    if (prevProps.companyID !== this.props.companyID) {         
      this.getUserFiles();  
    }
    
    if (prevProps.fileSpaces !== this.props.fileSpaces || prevProps.appAccess !== this.props.appAccess)
    {
      var newlimit = this.props.fileSpaces;
      this.setState({fileSpaces: newlimit, appAccess: this.props.appAccess});
    }
  }

  getUserFiles(callback = function(){}){
    if (this.props.companyID == null) return;
    Uploader.getJobsForUser(this.props.realm, this.props.companyID)
    .then((resp) => {
      if (this.state.modelviewerModel != null)
      {
        var newModelviewerModel = null;        
        resp.Jobs.forEach(element => {
          if (element.ItemID == this.state.modelviewerModel.ItemID)
          newModelviewerModel = element;
        });
        if (newModelviewerModel != null)
          this.setState({userFilesData: resp.Jobs, modelviewerModel: newModelviewerModel, tutorialOpen: !resp.Jobs || (resp.Jobs && resp.Jobs.length <= 2)})
        else
        this.setState({userFilesData: resp.Jobs, tutorialOpen: !resp.Jobs || (resp.Jobs && resp.Jobs.length <= 2)})
      }
      else
        this.setState({userFilesData: resp.Jobs, tutorialOpen: !resp.Jobs || (resp.Jobs && resp.Jobs.length <= 2)})
      callback();
    })
    .catch((err) => {
      //NOTE:gets in here when no data for user was found as well
      console.error("Failed to get user files " + JSON.stringify(err))
      this.setState({userFilesData: [], tutorialOpen: true})
      callback();
    })
  }

  setModel(data, initialOpen = false){      
    if (this.state.modelviewerModel != null) return;
    document.body.style.overflow = "hidden";
    this.setState({modelviewerModel: data, modelInitialOpen: initialOpen});
  }

  generateModelLink(id){
    if(id){        
      if (window.config.host.cdn.includes("vithrough"))
        return "https://viewer.vithrough.com/?id=" + id;
      else
      return window.config.host.cdn + "/" + this.props.realm + "/modelviewer/?id=" + id;
    }else{
      return null
    }
  }

  generateUserLink(id){
    if(id){
      if (window.config.host.cdn.includes("vithrough"))
        return "https://viewer.vithrough.com/?userID=" + id;
      else
      return window.config.host.cdn + "/" + this.props.realm + "/modelviewer/?userID=" + id;
    }else{
      return null
    }
  }

  copyToClipboard(id, isCompany = false, generateLink = true){
    let text =  generateLink ? (isCompany ? this.generateUserLink(id) : this.generateModelLink(id)) : id;
    if(text){
      navigator.clipboard.writeText(text).then(() => {
        //this is called when text was successfully copied
        this.setState({showNotice: true})
        this.closeNoticeTimeout = setTimeout(() => {
          this.closeNoticeTimeout = null;
          this.setState({showNotice: false})
        }, this.state.noticeSustain);
      },() => {
        /* Rejected - text failed to copy to the clipboard */
      });
    }
  }

  downloadImage(filename){
    if (filename == null || filename == '') filename = 'qr-code.png';
    let size = 512;
    let canvasSize = 592;
    let svg = document.getElementById("qr_code_renderer");
    svg.setAttribute("width", size);
    svg.setAttribute("height", size);
    if(svg != null){
      var data = new XMLSerializer().serializeToString(svg);
      var win = window.URL || window.webkitURL || window;
      var img = new Image();
      var blob = new Blob([data], { type: 'image/svg+xml' });
      var url = win.createObjectURL(blob);
      img.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = canvasSize;
        canvas.height = canvasSize;
        var context = canvas.getContext("2d");
        context.fillStyle = "white";
        context.fillRect(0, 0, canvas.width, canvas.height);
        context.drawImage(img, (canvasSize - size) / 2, (canvasSize - size) / 2);
        win.revokeObjectURL(url);
        var uri = canvas.toDataURL('image/png').replace('image/png', 'octet/stream');
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        a.href = uri
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(uri);
        document.body.removeChild(a);
      };

      img.src = url;
    }
  }

  downloadLinkQRCode(link, filename){
    this.setState({activeQrCode: link}, () => this.downloadImage(filename))
  }

  onUploadEnd(newFiles){  
    this.getUserFiles(this.showNewFiles(newFiles))
  }

  deleteModel(itemID)
  {
    const self = this;
    Dialog.Alert({
      title: TranslatedTexts.Delete,
      description: "Wollen Sie die Datei wirklich löschen?",
      options: [
          {label: TranslatedTexts.Delete, className:"dangerConfirm"},
          {label: TranslatedTexts.Cancel, className:""},
      ],
      panelOptions: {className: "messebau"}
      }).then((optionChosen) => {        
        if (optionChosen == 0) {
    
          Uploader.remJob(itemID)
            .then((resp) => {
              self.getUserFiles();        
            })
            .catch((err) => {        
          })
        }
      });
  }

  showNewFiles(newFiles){
    if(newFiles.length > 0){
      let file = newFiles[0]; //currently only showing the first one!!!
      if(file && file.ItemID){
        this.setModel(file, true)
      }
    }
  }
    
  editUser(userid) //NOTE: probably no longer needed?
  {

  }

  setCompanies(newCompanies){
    this.setState({companies: newCompanies})
  }

  render() {
    const userFilesCount = this.state.userFilesData ? this.state.userFilesData.length : 0;
    var spaceLeft = this.state.fileSpaces - userFilesCount;
      
    return (
      <div className='main_page'>
        <QRCodeSVG className='qr_code_renderer' id='qr_code_renderer' value={this.state.activeQrCode}/>
        {this.state.showQRCodeOverlay && <div className='qr_code_overlay'>
          <div className='qr_code_container'>
            <button className='qr_code_overlay_button' onClick={() => this.setState({showQRCodeOverlay: null})}><img src={CloseIcon}/></button>
            <QRCodeSVG className='qr_code_overlay_code' value={this.state.showQRCodeOverlay}/>
          </div>
        </div>}
        {this.state.showNotice && <div className='copied_notice'>
          <div className='copied_notice_left'><img src={CopyIcon}/></div>
          <div className='copied_notice_right'>{TranslatedTexts.LinkWasCopied}</div>
          </div>}
        <FileUploader
          ref={(cd) => this.fileUploader = cd}

          onUploadEnd = {(newFiles) => this.onUploadEnd(newFiles)}
          realm={this.props.realm}
          canEdit={this.props.canEdit}
          companyID={this.props.companyID}
          deleteModel={(itemID) => this.deleteModel(itemID)}
          maxConvertedFileSize = {this.state.modelWarningSize}
          spaceLeft= {spaceLeft}/>
        {this.props.isAdmin && 
          <CompanyList 
            isAdmin={this.props.isAdmin} 
            companyID={this.props.companyID} 
            realm={this.props.realm}
            onUpdateCompanies = {(data) => this.setCompanies(data)}
            onChangeUserAccess = {(indx) => this.editUser(indx)}/>}
        {this.props.isAdmin && 
          <UserList 
            isAdmin={this.props.isAdmin} 
            companyID={this.props.companyID} 
            realm={this.props.realm}
            companies={this.state.companies}
            onClickTableRow={(indx) => this.editUser(indx)}/>}
        <div className='tutorial_container'>
            <button onClick={() => this.setState({tutorialOpen: !this.state.tutorialOpen})}><img style={{width: '3em', height: 'auto'}} src={TutorialIcon}/></button>
            {this.state.tutorialOpen && <img src={TranslatedComponent.locale === "en" ? TutorialGraphic_EN : TutorialGraphic_DE}/>}
        </div>
        <FileList 
          realm={this.props.realm}
          companyID={this.props.companyID}
          fileSpaces = {this.props.fileSpaces}
          appAccess = {this.props.appAccess}
          canEdit = {this.props.canEdit}
          userFilesData = {this.state.userFilesData}
          copyLink={(id) => this.copyToClipboard(id, false, true)}
          onShowQRCode={(modelLink) => this.setState({showQRCodeOverlay: modelLink})}
          onUpdateQRCode={(link, fileName) => this.setState({activeQrCode: link}, () => this.downloadImage(fileName))}
          onClickOpenModel={(data) => this.setModel(data)}
          deleteModel={(itemID) => this.deleteModel(itemID)}
          onFilesChanged={() => this.getUserFiles()}
          modelWarningSize={this.state.modelWarningSize}/>
        <ModelviewerPopup 
          key={this.state.seed}
          modelviewerModel={this.state.modelviewerModel} 
          appAccess = {this.props.appAccess}
          canEdit={this.props.canEdit}
          onClose={() => {this.setState({ modelviewerModel: null}); document.body.style.overflowX = "hidden"; document.body.style.overflowY = "auto";}} 
          onModelUpdate={(modelId, hardReload = false) => { if (hardReload){ this.setState({ seed:Math.random()});} this.fileUploader.renewOngoingJobs(); this.getUserFiles(); }}
          downloadQRCode={(link,filename) => this.downloadLinkQRCode(link,filename)} 
          copyLink={(id) => this.copyToClipboard(id, false, false)}
          realm={this.props.realm}
          companyID={this.props.companyID}
          modelInitialOpen={this.state.modelInitialOpen}
          onCloseWarning={() => this.setState({modelInitialOpen: false})}
          modelWarningSize={this.state.modelWarningSize}/>
      </div>
        
      );
  }
}

export default MainPage;
