import React from 'react';
import './ProfilePage.less';
import CloseIcon from '../../images/close_icon.png'
import BinIcon from '../../images/bin_icon.png'
import PlusIcon from '../../images/+_icon.png'
import AccountIcon from '../../../base/images/account_icon.png';
import Dialog from '../Dialog/Dialog';
import { TranslatedTexts } from '../Locale/TranslatedTexts';
import { AdminHandler } from '../AdminHandler/AdminHandler';
import Dropdown from '../Dropdown/Dropdown';
import Checkmark from '../../images/faq_check.png'

class ProfilePage extends React.Component {
    
    companyListContent = null;
    notScrollableElements = [];
    
    static defaultProps = {
        profilePageActive: false,
        closeProfilePage: function(){},
        handlePasswortReset: function(){},
        remUser: function(){},
        userInfo: null,
        coworkers: null,
        coworkersLimit: null,
        UpdateUserRole: function(newVal, userMail){},
        updateCurrentUser: function(){}
    };

    hasStarted = false;

    constructor(props) {
        super(props);

        this.state = {
            coworkers: this.props.coworkers,
            addingCoworker: false,
            tempName: "",
            tempMail: "",
            tempUserRole: "1"
        };        
    }

    remUser(idx)
    {
        this.props.remUser(idx);
    }

    componentDidUpdate(prevProps, prevState){
        if (prevProps.coworkers !== this.props.coworkers) {          
          this.setState({coworkers: this.props.coworkers, addingCoworker: false});
        }  
        
        this.updateNotScrollableElements();              
    }

    UpdateTempUserRole(value){
        this.setState({tempUserRole: value});
    }

    componentWillUnmount(){
        if(this.notScrollableElements.length > 0){
            for (let index = 0; index < this.notScrollableElements.length; index++) {
                const element = this.notScrollableElements[index];
                element.removeEventListener('wheel', this.preventScroll, {passive: false})
            }
        }

        if(this.companyListContent){
            this.companyListContent.addEventListener('wheel', this.scrollUsersList);
            this.companyListContent.addEventListener('mousewheel', this.scrollUsersList);
            this.companyListContent.addEventListener('DOMMouseScroll', this.scrollUsersList);
        }
    }

    componentDidMount(){
        this.updateNotScrollableElements();

        this.companyListContent = document.getElementById("company_list_content");
        if(this.companyListContent){
            this.companyListContent.addEventListener('wheel', this.scrollUsersList);
            this.companyListContent.addEventListener('mousewheel', this.scrollUsersList);
            this.companyListContent.addEventListener('DOMMouseScroll', this.scrollUsersList);
        }
    }

    updateNotScrollableElements(){
        this.notScrollableElements = [];
        let notScrollable = document.getElementsByClassName("not_scrollable")

        for (let index = 0; index < notScrollable.length; index++) {
            const element = notScrollable[index];
            this.notScrollableElements.concat(element.getElementsByTagName("*"));
            this.notScrollableElements.push(element);
        }

        if(this.notScrollableElements.length > 0){
            for (let index = 0; index < this.notScrollableElements.length; index++) {
                const element = this.notScrollableElements[index];
                element.addEventListener('wheel', this.preventScroll, {passive: false});
            }
        }
    }
      
    onSave()
    {        
        Dialog.Alert({
            title: "Produkt löschen",
            description: "Wollen Sie das Produkt mit der ID wirklich löschen?",
            options: [
                {label: "LÖSCHEN", className:"positiveConfirm"},
                {label: "Abbrechen", className:"cancel"},
            ]
        })
    }    

    closePage()
    {        
        this.props.closeProfilePage();
        this.setState({addingCoworker: false});
    }

    resetPassword()
    {
        this.props.handlePasswortReset();
    }    
    setAddingCoworker()
    {        
        if (this.state.addingCoworker)
            this.resetInputFields();
        else
            this.setState({addingCoworker: !this.state.addingCoworker});
    }

    addCoworker(){
        const self = this;
        if (this.state.tempName != "" && this.state.tempMail != ""){
            var myself = (this.state.coworkers != null && this.props.userInfo != null && this.props.userInfo[0] != null) ? this.state.coworkers.find((w) => w.UserEmail == this.props.userInfo[0]) : null;            
            
            AdminHandler.addUser(this.state.tempName, this.state.tempMail, this.state.coworkers[0].UserLocale, this.state.tempUserRole, this.state.coworkers[0].Company, this.props.userInfo[2])
            .then((res) => {            
              self.resetInputFields();
              self.props.updateCurrentUser();    
            })
            .catch((err) => {
                console.error(err);
                Dialog.Alert({
                    title: TranslatedTexts.LoadError,
                    description: TranslatedTexts.UserLimitError,
                    options: [
                        {label: "OK", className:""},
                    ],
                    panelOptions: {className: "messebau oneButtonDialog"}
                })
            })
        }
    }

    resetInputFields()
    {
        this.setState({addingCoworker: false, tempMail: "", tempName: "", tempUserRole: "1"});
    }


    UpdateUserRole(newVal, userMail)
    {            
      var newUsers = this.state.coworkers;
      newUsers.find((e) => e.UserEmail == userMail).UserRole = newVal;
      this.setState({coworkers: newUsers});      
      AdminHandler.updateUserRole(newVal, userMail)
      .then((res) => {})
      .catch((err) =>{})
    }

    getCoworkerList()
    {        
        if (this.props.coworkers == null) return;
        var myself = this.props.coworkers.find((w) => w.UserEmail == this.props.userInfo[0]);
        let rows = [];
        if (this.props.coworkers == null) return rows;
        this.props.coworkers.map((coworker,index) => {        
            rows.push(
                <div key={index+1}>
                    <div className='company_worker_existing' title={coworker.UserEmail} style={{display: "flex", flexDirection: "Row"}}>
                        <p style={{width: "60%"}} >{coworker.UserName}</p>
                        {
                            myself != null && myself.UserRole == 1 &&
                            <select value={coworker.UserRole} disabled={coworker.UserEmail == myself.UserEmail} onChange={(e) => this.UpdateUserRole(e.target.value, coworker.UserEmail)} type="select" style={{width: "20%"}}>
                                <option value="1">Admin</option>
                                <option value="2">Editor</option>
                                <option value="3">User</option>
                            </select>
                        }
                        {/*<img src={KeyIcon}></img>*/}
                        {
                            myself != null && myself.UserRole == 1 &&
                            <button disabled={coworker.UserEmail == myself.UserEmail} onClick={() => this.remUser(index)}><img src={BinIcon}></img></button>
                        }                                                
                    </div>
                    
                    <div style={{height: "0.1vh", backgroundColor: "white"}}></div>                            
                </div>);
        }); 
        return rows;       
    }

    focusUsersList(e){
        e.preventDefault();
        let content = document.getElementById('company_list_content');
        if(content){
            content.focus({ focusVisible: false });
        }
    }

    preventScroll(e){
        e.preventDefault();
        e.stopPropagation();
        return false;
    }

    scrollUsersList(e){
        let delta = e.wheelDelta || -e.detail;
        this.scrollTop += ( delta < 0 ? 1 : -1 ) * 30;
        e.preventDefault();
        e.stopPropagation();
    }

    render() {
        const coworkers = this.props.coworkers;
        const coworkersLimit = this.props.coworkersLimit;
        const canAddCoworkers = coworkersLimit == null ||  (this.props.coworkers && coworkersLimit > this.props.coworkers.length);
        var myself = (this.state.coworkers != null && this.props.userInfo != null && this.props.userInfo[0] != null) ? this.state.coworkers.find((w) => w.UserEmail == this.props.userInfo[0]) : null;
        var isCompanyAdmin = myself != null ? myself.UserRole < 2 : false;
        return (
            <div className={this.props.profilePageActive?'profilePage profilePageActive' : 'profilePage profilePageInactive'}>
                <div style={{margin: "10vh 3vw"}}>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <img src={AccountIcon}></img>
                        <button title="close" onClick={() => this.closePage()}><img src={CloseIcon}></img></button>
                    </div>
                    <div>
                        <h1>{TranslatedTexts.AccountSettings}</h1>
                    </div>
                    {
                        this.props.userInfo != null &&
                        <div>                        
                        <p className='no_edit'>{this.props.userInfo[1]}</p>
                        <p className='no_edit'>{this.props.userInfo[0]}</p>
                        <p className='no_edit'></p>
                        <p className='no_edit'>{this.props.userInfo[2]}</p>
                        </div>
                    }
                    
                    <div>
                        <button className='passwort_reset' onClick={() => this.resetPassword()} title={TranslatedTexts.ResetPassword}>{TranslatedTexts.ResetPassword}</button>
                    </div>
                    <div style={{height: "0.1vh", backgroundColor: "white", margin: "2em 0"}}>&nbsp;</div>
                    <div>
                        <p className='no_edit'>{TranslatedTexts.Coworkers + " " + (coworkers ? coworkers.length : 0) + "/" + (coworkersLimit ? coworkersLimit : "?")}</p>
                        <div className='company_list'>
                            <div id='company_list_content' onMouseEnter={(e) => this.focusUsersList(e)}>
                            {
                                this.getCoworkerList()                                
                            }
                            </div>
                            <div>
                            {this.state.addingCoworker &&
                                <div className='not_scrollable'>
                                    <div className='company_worker_new' title={TranslatedTexts.AddUser} style={{display: "flex", flexDirection: "Row"}}>
                                        <input placeholder="Name" type='text' value={this.state.tempName} onChange={(e) => this.setState({tempName: e.target.value})} style={{width: "40%"}} ></input>
                                        <input placeholder="E-Mail" type='text' value={this.state.tempMail} onChange={(e) => this.setState({tempMail: e.target.value})}  style={{width: "40%"}} ></input>
                                        <div className='add_worker_dropdown_container'>
                                            <Dropdown className="company_worker_dropdown" value={this.state.tempUserRole ? this.state.tempUserRole : 0} selections={[{text: "Admin", value: 1}, {text: "Editor", value: 2}, {text:"User", value: 3}]} onChange={(value) => this.UpdateTempUserRole(value)}/>
                                        </div>
                                    </div>
                                </div>                                                                                                        
                            }
                            <div className='not_scrollable' style={{display: "flex", flexDirection: "Row", justifySelf: "end", justifyContent: "space-between"}}>
                                {
                                    this.props.coworkers != null && isCompanyAdmin && canAddCoworkers &&
                                    <button className={this.state.addingCoworker ? "add_worker_cancel" : "add_worker"} onClick={() => {if(canAddCoworkers){this.setAddingCoworker()}}} title="Add User">{this.state.addingCoworker ? TranslatedTexts.AddCoworkerCancel : TranslatedTexts.AddCoworker}</button>                                    
                                }
                                {
                                    canAddCoworkers && this.state.addingCoworker &&
                                    <button className={"add_worker"} onClick={() => {if(canAddCoworkers){this.addCoworker()}}}>{TranslatedTexts.AddCoworkerConfirm}</button>
                                }
                            </div>
                            </div>
                        </div>
                    </div>
                    {
                        
                        <div style={{display: "flex", flexDirection: "Row", justifyContent: "flex-end"}}>
                        {
                            //<button className='armodelviewer_button' title="speichen" onClick={() => this.testKeycloakFunctions()}>{TranslatedTexts.Save</button>
                        }
                        </div>
                    }
                </div>
            </div>
        );            
    }
}

export default ProfilePage;
