import React from 'react';
import "react-step-progress-bar/styles.css";
import './ProgressView.less'
import BinIcon from '../../images/bin_icon.png';
import { TranslatedTexts } from '../Locale/TranslatedTexts';
import { ProgressBar, Step } from "react-step-progress-bar";

class ProgressView extends React.Component {

    static defaultProps = {
        files: [],
        deleteModel: function(){}
      };
    constructor(props) {
        super(props);

        this.state = {
          files: this.props.files,
        };
    }

    componentDidUpdate(prevProps, prevState){              
        if (prevProps.files.length != this.props.files.length) {          
          this.setState({files: this.props.files})
        }
        else
        {
          var equal = true;
          this.state.files.forEach((e) => 
          {
            var finding = this.props.files.find((f) => e.ItemID == f.ItemID && e.State == f.State && e["UploadProgress"] == f["UploadProgress"]);
            if (finding == null)
            {
              equal = false;
            }            
          })
          if (!equal)
          {            
            this.setState({files: this.props.files.slice()})
          }          
        }
      }
      
      removeJob(itemID)
      {
        this.props.deleteModel(itemID);
      }

      getStateDesciption(loadState){
        if(loadState < 0){
          return TranslatedTexts.LoadError;
        }else{
          switch(loadState){ 
            case 0:
              return TranslatedTexts.Uploading;
            case 1:          
              return TranslatedTexts.LoadUploadFinished;
            case 2:
              return TranslatedTexts.LoadQueued;
            case 3:
              return TranslatedTexts.LoadServerGetFiles;
            case 4:
              return TranslatedTexts.LoadConvert;
            case 5:
              return TranslatedTexts.LoadDeploy;
            default:
              return TranslatedTexts.LoadError;
          }
        }
      }

    render() {
        var canClose = this.props.files != null ? this.props.files.find((f) => f.State == 0) == null : false;
        return (
            <div className='ProgressViewMain'>
                <div className='ProgressViewHeader'>
                    <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                    <div className='ProgressHeaderInner'>
                        <p className='ProgressDescription'>{canClose ? TranslatedTexts.WindowCloseAllow : TranslatedTexts.WindowCloseWarning}</p>
                        <div className='WhiteLine'/>                        
                    </div>
                </div>    
                <div className='ProgressHeaderBody'>
                    { this.state.files != null && this.state.files.map((data, idx) =>
                    {
                        var progress = data["State"]/6*100;//data["Progress"] + "%";
                        var description = this.getStateDesciption(data["State"]);                        
                        if (data["State"] == 0 && data["UploadProgress"])
                        {
                          progress = data["UploadProgress"] / 3.0 * 100;
                          description += " (" + Math.floor( progress * 3 ) + "%)";
                        }
                          
                        return (
                        <div className='FileRow' key={idx+1}>
                        <div className='FileRowInner' title={data["FileName"]}>
                            <div className='Filename'>
                                <p>{data["FileName"].length > 17 ? data["FileName"].slice(0, 16) + "..." : data["FileName"]}</p>
                            </div>
                            <div className='FileProgress'>
                                <div className='Paragraph'>{description}</div>                                
                                <ProgressBar
                                  percent={progress}
                                  hasStepZero={false}                                  
                                  filledBackground="linear-gradient(to right, #88A8A8, #6E9E9E)"
                                > 
                                <Step>
                                    {({ accomplished }) => (
                                      <div
                                      style={{ 
                                        display: "none",
                                        border: "transparent",
                                        width: "10px",
                                        border: "solid 2px white",
                                        backgroundColor: "rgba(138, 173, 173)",
                                        borderRadius: "50%"}}
                                    >
                                      &nbsp;
                                      </div>
                                    )}
                                  </Step>
                                  <Step>
                                  {({ accomplished }) => (
                                      <div
                                      style={{ 
                                        filter: `grayscale(${accomplished ? 0 : 80}%)`,
                                        border: "transparent",
                                        width: "3px",
                                        height: "10px",
                                        //border: "solid 2px white",
                                        backgroundColor: "rgba(255, 255, 255, 0.6)",
                                        //borderRadius: "50%"
                                      }}
                                    >
                                      &nbsp;
                                      </div>
                                    )}
                                  </Step>
                                  <Step>
                                  {({ accomplished }) => (
                                      <div
                                      style={{ 
                                        filter: `grayscale(${accomplished ? 0 : 80}%)`,
                                        border: "transparent",
                                        width: "3px",
                                        height: "10px",
                                        //border: "solid 2px white",
                                        backgroundColor: "rgba(255, 255, 255, 0.6)",
                                        //borderRadius: "50%"
                                      }}
                                    >
                                      &nbsp;
                                      </div>
                                    )}
                                  </Step>
                                  <Step>
                                  {({ accomplished }) => (
                                      <div
                                      style={{ 
                                        filter: `grayscale(${accomplished ? 0 : 80}%)`,
                                        border: "transparent",
                                        width: "3px",
                                        height: "10px",
                                        //border: "solid 2px white",
                                        backgroundColor: "rgba(255, 255, 255, 0.6)",
                                        //borderRadius: "50%"
                                      }}
                                    >
                                      &nbsp;
                                      </div>
                                    )}
                                  </Step>
                                  <Step>
                                  {({ accomplished }) => (
                                      <div
                                      style={{ 
                                        filter: `grayscale(${accomplished ? 0 : 80}%)`,
                                        border: "transparent",
                                        width: "3px",
                                        height: "10px",
                                        //border: "solid 2px white",
                                        backgroundColor: "rgba(255, 255, 255, 0.6)",
                                        //borderRadius: "50%"
                                      }}
                                    >
                                      &nbsp;
                                      </div>
                                    )}
                                  </Step>                                 
                                  <Step>
                                  {({ accomplished }) => (
                                      <div
                                      style={{ 
                                        display: "none",
                                        border: "transparent",
                                        width: "10px",
                                        border: "solid 2px white",
                                        backgroundColor: "rgba(138, 173, 173)",
                                        borderRadius: "50%"}}
                                    >
                                      &nbsp;
                                      </div>
                                    )}
                                  </Step>
                                </ProgressBar>
                                {
                                  /*
                                
                                <div className='ProgressBarOuter'>
                                    <div style={{width: progress}} className='ProgressBarInner'>
                                    </div>
                                </div>
                                */}
                            </div>
                        </div>
                        <button className='fileRemButton' onClick={(e)=> {this.removeJob(data["ItemID"]);}}><img src={BinIcon}/></button>
                    </div>);
                    })}
                    
                </div>            
            </div>
        );
    }
}

export default ProgressView;
