import React, { isValidElement } from 'react';
import { Uploader } from '../Uploader/Uploader';
import FileReqIcon from '../../images/anforderungen_icon.png';
import CloseIcon from '../../images/x_icon.png';
import ScaleIcon from '../../images/x_icon.png';
import BinIcon from '../../images/bin_icon.png';
import { TranslatedTexts } from '../Locale/TranslatedTexts';
import TranslatedComponent from '../../../../src/base/components/Locale/TranslatedComponent';
import Dialog from '../Dialog/Dialog';
import CheckboxWithLabel from '../CheckboxWithLabel/CheckboxWithLabel';
import './FilePicker.less'
import ProgressView from '../ProgressView/ProgressView';

class FilePicker extends React.Component {

  uploadChecker = null
  uploadCheckerTimeout = null
  fileUploadRef = null
  uploadingProgress = []

  static defaultProps = {
      realm: null,
      companyID: null,
      canEdit: false,
      uploadPath: "/Files",
      outputFormat: 'gltf',
      inputFormats: ['fbx', 'obj', 'dae', 'stl', 'glb', 'gltf' , 'zip', 'dxf', 'wrl', 'step', 'stp'],
      minFileSize: 1,
      maxFileSize: 2000000000,//bytes //=2GB
      maxConvertedFileSize: 10000000, //byte //=10mb
      minFilenameSize: 4,
      maxFilenameSize: 50,
      allowedFilenameCharacters: /^([a-zA-Z0-9äöüÄÖÜ ._-]+)$/, // allows spaces inside a string and restricts special characters.It Only allows a-z, A-Z, 0-9, Space, Underscore and dash
      onUploadEnd: function(){},
      deleteModel: function(){},      
      spaceLeft: 0,
      timeout: 120000 // ca. 120000 -> 2min
  };

  constructor(props) {
      super(props);

      this.state = {
        dragOver: false,
        selectedFiles: [],
        uploadingFiles: [],
        errorFiles: [],
        outputFormat: this.props.outputFormat, //'gltf','glb'
        uploading: false, //'dropData','uploading','restrictions', 
        progressBarState: 0,
        showingFileReq: false,
        adjustingScale: false,
        dropZonePageIndex: 0,      
      };

      this.fileUploadRef = React.createRef();
      this.FilePositionWall = React.createRef();   
      this.FilePositionFloor= React.createRef();   
      this.FileKeepOffsetKeep= React.createRef();   
      this.FileKeepOffsetCenter= React.createRef();   
      this.originalScaleCheckbox= React.createRef();   
      this.updateUploadValue = this.updateUploadValue.bind(this)
  }

  componentDidMount() {
    if (this.uploadChecker == null)
    {
      this.uploadChecker = setInterval(() => {
        this.checkUploadProgress();
      }, 2000);
    }    
    }

  onFileChange(files, e){    
    //NOTE:currently allowing only one file at a time! Later, a system with multiple files is planed    
    if(files && files.length > 1){
      Dialog.Alert({
        title: TranslatedTexts.ThereWasAnError,
        description: TranslatedTexts.OnlyOneFileError,
        options: [
            {label: TranslatedTexts.OK, className:"cancel"},
        ]
      })

      return;
    }    
    let validFiles = [];
    if(files && files.length > 0){
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        if(this.fileIsValid(file)){
          validFiles.push({"File": file, "KeepOffset":false, "Scale":"1.0", "Position": false, "Animate": false, "BaseUnit": "m", "UpAxis": 0, "ForwardAxis": 0})
        }
      }
    }

    this.setState({selectedFiles: validFiles, dragOver: false, showingFileReq: false});    
    e.stopPropagation();
  };

  updateUploadValue(val, id)
  {
    var jobsFile = this.state.uploadingFiles.find((f) => f.ItemID == id);    
    if (jobsFile != null)
      jobsFile["UploadProgress"] = val;

    this.forceUpdate();
  }

  onFileUpload = (e) => {    
    if (this.state.selectedFiles == null || this.state.selectedFiles.length == 0) {
      
      Dialog.Alert({
        title: TranslatedTexts.ThereWasAnError,
        description: TranslatedTexts.NoFileError,
        options: [
            {label: TranslatedTexts.OK, className:"cancel"},
        ]
      })
      return;
    }
    else {
        this.setState({uploading: true, dropZonePageIndex: 0})
        this.state.selectedFiles.forEach(file => {
            this.handleUploadFile(file, e.target);
        });       
    }

    e.stopPropagation();
  };

  renewOngoingJobs()
  {
    if (this.uploadChecker == null)
      {
        this.uploadChecker = setInterval(() => {
          this.checkUploadProgress();
        }, 2000);        
      }
  }

  handleUploadFile(file, htmlElem){
    const selectedFile = file;
    if(this.props.realm && this.props.companyID && this.fileIsValid(file.File)){ //for security reasons, this is checked on file change and on file upload
      Uploader.addJob(
        this.props.realm,
        this.props.uploadPath, 
        this.props.companyID, 
        this.props.outputFormat,
        selectedFile.File.name.split(".")[0],
        file.KeepOffset ? "1" : "0", 
        file.Scale,
        file.Position ? "1" : "0",
        file.Animate ? "1" : "0",
        "#eeeeee",
        file.BaseUnit,
        file.UpAxis ? 1 : 0,
        file.ForwardAxis ? 1 : 0
        )
        .then((resp) => {
          if (this.fileUploadRef != null && this.fileUploadRef.current != null)
          {
            this.fileUploadRef.current.value = null;
            this.setState({selectedFiles: [], dropZonePageIndex: 0});
          }
          if (this.uploadChecker == null)
          {
            this.uploadChecker = setInterval(() => {
              this.checkUploadProgress();
            }, 2000);        
          }
          this.checkUploadProgress();
          Uploader.uploadFile(
            this.props.realm,
            selectedFile.File,
            this.props.uploadPath, 
            this.props.companyID,
            resp.ItemID,
            this.updateUploadValue
          );
        })
        .catch((err) => {
          console.log(err)
          Dialog.Alert({
            title: TranslatedTexts.ThereWasAnError,
            description: err,
            options: [
                {label: TranslatedTexts.OK, className:"cancel"},
            ]
          })
          this.state.errorFiles.push({"File" : file, "Response": err, "Success" : false, "Reason": "Upload Failed"})
        })        
    }else{
      this.state.errorFiles.push({"File" : file, "Response": null, "Success" : false, "Reason": "Values Not Valid"})
      htmlElem.dragOver = false;
    }
  }

  uploadTimeout(){
    if(this.uploadChecker){
      Dialog.Alert({
        title: TranslatedTexts.ThereWasAnError,
        description: TranslatedTexts.UploadError,
        options: [
            {label: TranslatedTexts.OK, className:"cancel"},
        ]
      })
      this.resetUpload();
    }
  }

  resetUpload(full = false){    
    /*
    
    */        
    if (full)
    {
      clearInterval(this.uploadChecker);
      this.uploadCheckerTimeout = null;    
      this.uploadChecker = null;
      this.setState({uploading: false, errorFiles: [], progressBarState: 0, uploadingFiles: [], dropZonePageIndex: 0})
    }
    else
    {
      if (this.fileUploadRef != null && this.fileUploadRef.current != null)
        this.fileUploadRef.current.value = null;
      this.setState({uploading: false, selectedFiles: [], errorFiles: [], progressBarState: 0, dropZonePageIndex: 0})
    }
  }

  updateJobProgess(newState)
  {
    if(newState >= 0){
      this.setState({progressBarState: newState})
    }
  }

  checkIfFileFinished(file)
  {    
    let uploaded = [];    
    Uploader.getJobState(file.ItemID,this.props.companyID)
    .then((resp) => {
    if (resp.Job["State"] == 1){
      uploaded.push(resp.Job)  
      this.props.onUploadEnd(uploaded)
    }else if (resp.Job["State"] < 0){
      Dialog.Alert({
        title: TranslatedTexts.ThereWasAnError,
        description: resp.Job["Errors"] != "" 
          ? resp.Job["FileName"] + resp.Job["FileType"] + ": " + resp.Job["Errors"] 
          : TranslatedTexts.UploadErrorFailedFiles + resp.Job["FileName"] + resp.Job["FileType"],
        options: [
            {label: TranslatedTexts.OK, className:"cancel"},
        ]
      })      
      this.props.onUploadEnd(uploaded)
    }})
    .catch((err) => {})
  }

  checkUploadProgress(){    
    Uploader.getOngoingJobs(this.props.companyID)
    .then((resp) => {
      if(resp && resp.Jobs){
        this.state.uploadingFiles.forEach ((file) =>
        {
          var jobsFile = resp.Jobs.find((f) => f.ItemID == file.ItemID);
          if(jobsFile == null)
          {            
            this.checkIfFileFinished(file);
          }
          else
          {            
            jobsFile["UploadProgress"] = file["UploadProgress"];
          }
        });
        this.setState({uploadingFiles: resp.Jobs});        
      }
      else
      {        
        this.state.uploadingFiles.forEach ((file)=>        
        {          
          this.checkIfFileFinished(file);
          
        });
        this.resetUpload(true);        
      }
    })
    .catch((err) => {
      this.state.uploadingFiles.forEach ((file)=>        
        {          
          this.checkIfFileFinished(file);
          
        });        
        this.resetUpload(true);      
    })
  }

  fileIsValid(file){
    if(!file || !file.name){
      Dialog.Alert({
        title: TranslatedTexts.ThereWasAnError,
        description: TranslatedTexts.FileNotValidError,
        options: [
            {label: TranslatedTexts.OK, className:"cancel"},
        ]
      })
      return false;
    }

    //TODO: In order to have the following messages translatable, we should think about a system which takes inputs in the dialog.alert function
    if(this.props.minFileSize != null && this.props.maxFileSize != null){
        if (!this.checkFileSize(file)) {
          Dialog.Alert({
            title: TranslatedTexts.ThereWasAnError,
            description: TranslatedTexts.parse(TranslatedTexts.FileBadError, [file.name, this.formatFileSize(this.props.minFileSize), this.formatFileSize(this.props.maxFileSize)]),
            options: [
                {label: TranslatedTexts.OK},
            ]
          })            
            return false;
        }
    }

    if(this.props.minFilenameSize != null && this.props.maxFileSize != null){
        if(!this.checkFileName(file.name)){
          Dialog.Alert({
            title: TranslatedTexts.ThereWasAnError,
            description: TranslatedTexts.parse(TranslatedTexts.FilenameBadError, [file.name, this.props.minFilenameSize, this.props.maxFilenameSize]),
            options: [
                {label: TranslatedTexts.OK},
            ]
          })            
            return false;
        }
    }
    
    if(!this.checkFileFormat(file.name)){
      Dialog.Alert({
        title: TranslatedTexts.ThereWasAnError,
        description: TranslatedTexts.parse(TranslatedTexts.FileformatBadError, [file.name, this.props.inputFormats]),
        options: [
            {label: TranslatedTexts.OK},
        ]
      })
      return false;
    }
    
    return true;
  }

formatFileSize(bytes, decimalPoint) {
  if(bytes == 0) return '0 Bytes';
  var k = 1000,
      dm = decimalPoint || 2,
      sizes = ['Byte(s)', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

checkFileFormat(filename){
  let extension = filename.split('.').pop();
  if(this.props.inputFormats.includes(extension.toLowerCase())){
    return true;
  }

  return false;
}


checkFileSize(file){
    if(file.size > this.props.maxFileSize || file.size < this.props.minFileSize){
        return false;
    }

    return true;
}

checkFileName(filename){
    if(filename == null || filename == "" || !this.checkFileNamePoints(filename)){
        return false;
    }

    let nameParts = filename.split(".");
    let firstNamePart = nameParts[0];

    if(firstNamePart.length > this.props.maxFilenameSize || firstNamePart.length < this.props.minFilenameSize || !this.checkFilenameCharacters(firstNamePart)){
        return false;
    }

    return true;
}

checkFileNamePoints(filename){
    let filenameParts = filename.split(".");
    if(filenameParts.length > 2){
        return false;
    }

    return true;
}

checkFilenameCharacters(filename){
    if(!this.props.allowedFilenameCharacters.exec(filename)){
        return false;
    }
    return true;
}

  getPendingJobs = (self) => {
    Uploader.getPendingJobs((data) => {      
      self.setState({ itemID: data.data[0].ItemID })
      
      self.getJob(self);

    })
  }

  getJob = (self) => {
    Uploader.getJob(self.state.itemID);
  }

  dropHandler(ev) {
    ev.preventDefault();
    const files = [];
  
    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      [...ev.dataTransfer.items].forEach((item, i) => {
        // If dropped items aren't files, reject them
        if (item.kind === "file") {
          files.push(item.getAsFile());
        }
      });
    } else {
      // Use DataTransfer interface to access the file(s)
      [...ev.dataTransfer.files].forEach((file, i) => {
        files.push(file);
      });
    }

    this.onFileChange(files, ev);
  }

  dragOverHandler(ev) {
    if(!this.state.dragOver){
      this.setState({dragOver: true});
    }    
    ev.preventDefault();
    ev.stopPropagation();
  }

  dragEnterHandler(e){
    this.setState({dragOver: true});
    e.preventDefault();
  }

  dragLeaveHandler(e){
    if(this.state.dragOver){      
      this.setState({dragOver: false});
    }    
    e.preventDefault();
    e.stopPropagation();
  }

  updateFileCheckBoxValue(e, idx, property, value){
    const c = Object.assign({}, this.state);
    if(c.selectedFiles[idx]){
      c.selectedFiles[idx][property] = value;      
      this.setState(c);
    }
    e.stopPropagation();
  }

  setFileScaleValue(e, idx, value){    
    const c = Object.assign({}, this.state);
    if(e && e.target && c.selectedFiles[idx]){
      if(e.target.checked){
        //this.setScaleAdjustment(e, false)
        c.selectedFiles[idx]["Scale"] = (value/100);
      }else{
        c.adjustingScale = true;
      }
      this.setState(c);      
    }
    e.stopPropagation();
  }

  updateFileScaleValue(e, idx){
    const c = Object.assign({}, this.state);
    if(e && e.target && c.selectedFiles[idx]){
      c.selectedFiles[idx]["Scale"] = (e.target.value/100);      
      this.setState(c);
    }
    e.stopPropagation();
  }

  showFileRequirements(e){
    e.preventDefault();    
    this.setState({showingFileReq: !this.state.showingFileReq})    
    e.stopPropagation();
  }

  uploadThroughHtmlElement(e){  
    if(this.state.adjustingScale){
      this.setScaleAdjustment(e, false)
    }else if(this.state.selectedFiles.length == 0){      
      this.fileUploadRef.current.click()
      e.stopPropagation();
    }
  }

  setScaleAdjustment(e, active){    
    this.setState({adjustingScale: active})     
  }
  
  getStateDesciption(loadState){
    if(loadState < 0){
      return TranslatedTexts.LoadError;
    }else{
      switch(loadState){ 
        case 0:
          return TranslatedTexts.Uploading;
        case 1:          
          return TranslatedTexts.LoadUploadFinished;
        case 2:
          return TranslatedTexts.LoadQueued;
        case 3:
          return TranslatedTexts.LoadServerGetFiles;
        case 4:
          return TranslatedTexts.LoadConvert;
        case 5:
          return TranslatedTexts.LoadDeploy;
        default:
          return TranslatedTexts.LoadError;
      }
    }
  }

  render() {    
    const state = this.state;
    const props = this.props;
    const filesSelected = state.selectedFiles && state.selectedFiles.length != 0;    
      
    const uploadButtonState = filesSelected ? (state.dropZonePageIndex < 1 ? "selected" : "upload") : "empty";

    return (
      <div className='filepicker_main'>
      <div id='file_picker' className='file_picker'>
        <div id='pickerContent' className='pickerContent'>
          <div 
            id='drop_zone'
            className={state.dragOver ? "drop_zone_dragOver" : "drop_zone"} 
            onDrop={(e) => this.dropHandler(e)} 
            onDragOver={(e) => this.dragOverHandler(e)} 
            onDragLeave={(e) => this.dragLeaveHandler(e)}
            onDragEnd={(e) => this.dragLeaveHandler(e)}
            onClick={(e) => {this.uploadThroughHtmlElement(e)}}
            >
            <div className='drop_zone_content'>
              <div className='file_requirements'>
                  <div className='file_requirements_tooltip'>
                    {TranslatedTexts.FileReqText}
                  </div>
              </div>
              <button className='file_requirements_button' id='file_requirements_button_id' title={TranslatedTexts.FileReqButton} onClick={(e) => this.showFileRequirements(e)}>
                <img alt="" src={state.showingFileReq ? CloseIcon : FileReqIcon}/>
              </button>
              {!filesSelected && !state.showingFileReq && <label id='description' className='description'>{!(props.spaceLeft > state.uploadingFiles.length) ? TranslatedTexts.ModelLimit1 : TranslatedTexts.FileReq}</label>}
              {!filesSelected && !state.showingFileReq && <label id='drop_zone_hint' className='drop_zone_hint'>{!(props.spaceLeft > state.uploadingFiles.length) ? TranslatedTexts.ModelLimit2: TranslatedTexts.DropFile}</label>}
              {filesSelected && !state.showingFileReq && 
                  state.selectedFiles.map((file, idx) => {                  
                    const selectedFile = file.File;
                    return (
                      <div className='file_data' key={"file_data-" + idx}>
                        <label className='labelTitle'>{TranslatedTexts.FileName}</label>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                          <div>                        
                          <label>{selectedFile.name}</label>                        
                          </div>
                          <div>
                          <button onClick={(e)=> {this.resetUpload();}} style={{marginLeft: '0.5em'}}><img src={BinIcon}/></button>
                          </div>
                        </div>
                        {this.state.dropZonePageIndex == 0 && 
                        <div className='file_data_list'>
                          <div className='file_data_element'>
                          <div title={TranslatedTexts.FilePosition_Help}><label>{TranslatedTexts.FilePosition_Headline}</label></div>
                            <div style={{width: "4.5rem"}}>
                              <CheckboxWithLabel 
                                checkboxPosition="right" 
                                labelContent={TranslatedTexts.FilePositionWall} 
                                onClick={(e, newChecked) => this.updateFileCheckBoxValue(e, idx, "Position", newChecked)} 
                                checked={file["Position"]} 
                                value={file["Position"]} 
                              />
                              <CheckboxWithLabel 
                                checkboxPosition="right" 
                                checked={!file["Position"]} 
                                labelContent={TranslatedTexts.FilePositionFloor}
                                onClick={(e, newChecked) => this.updateFileCheckBoxValue(e, idx, "Position", !newChecked)}
                              />
                            </div>
                          </div>
                          <div className='file_data_element'>
                          <div title={TranslatedTexts.FileKeepOffset_Help}><label>{TranslatedTexts.FileKeepOffset}</label></div>
                            <div style={TranslatedComponent.locale == "en" ?{width: "5.5rem"} : {width: "7rem"}}>
                              <CheckboxWithLabel 
                                checkboxPosition="right" 
                                checked={file["KeepOffset"]||file["Scale"] != 1.0} 
                                labelContent={TranslatedTexts.FileKeepOffsetKeep} 
                                onClick={(e, newChecked) => this.updateFileCheckBoxValue(e, idx, "KeepOffset", newChecked)}
                              />
                              <CheckboxWithLabel 
                                checkboxPosition="right" 
                                checked={!file["KeepOffset"]&&file["Scale"] == 1.0} 
                                onClick={(e, newChecked) => this.updateFileCheckBoxValue(e, idx, "KeepOffset", !newChecked)} 
                                labelContent={TranslatedTexts.FileKeepOffsetCenter}
                              />
                            </div>
                          </div>
                          <div className='file_data_element'>
                            <div title={TranslatedTexts.FileScale_Help}><label>{TranslatedTexts.FileScale}</label></div>
                            <div style={TranslatedComponent.locale == "en" ? {width: "7rem"} : {width: "7.5rem"}}>
                              <CheckboxWithLabel 
                                checkboxPosition="right" 
                                value={(file["Scale"] == 1.0)} 
                                checked={(file["Scale"] == 1.0)} 
                                labelContent={TranslatedTexts.FileScaleOriginal} 
                                onClick={(e) => this.setFileScaleValue(e, idx, 100)}
                              />
                              <div className='flex_data_element_scaling'>
                                <label className='file_data_element_option'>{TranslatedTexts.FileScaleCustom} (%): </label>                            
                                <input  style={{width: '3em'}} type="number" value={Math.round(file["Scale"]*100).toString()} min="1" step="1" pattern="\d*" onBlur={() => this.setState({adjustingScale: false})} onClick={(e) => {e.stopPropagation();}} onChange={(e) => this.updateFileScaleValue(e, idx)}></input>
                              </div>
                            </div>
                          </div>
                        </div>}
                        {this.state.dropZonePageIndex == 1 && 
                        <div className='file_data_list'>
                          <div className='file_data_element'>
                            <div title={TranslatedTexts.FilePickerUnits_Help}><label>{TranslatedTexts.FilePickerUnitsTitle}</label></div>
                            <div style={{width: "7rem"}}>
                              <CheckboxWithLabel 
                                checkboxPosition="right" 
                                labelContent={TranslatedTexts.ModelSettingsUnitMM} 
                                onClick={(e, newChecked) => this.updateFileCheckBoxValue(e, idx, "BaseUnit", "mm")} 
                                checked={file["BaseUnit"] == "mm"} 
                                value={file["BaseUnit"]  == "mm"} 
                                />
                              <CheckboxWithLabel 
                                checkboxPosition="right" 
                                checked={file["BaseUnit"] == "cm"} 
                                value={file["BaseUnit"] == "cm"} 
                                labelContent={TranslatedTexts.ModelSettingsUnitCM} 
                                onClick={(e, newChecked) => this.updateFileCheckBoxValue(e, idx, "BaseUnit", "cm")}
                              />
                              <CheckboxWithLabel 
                                checkboxPosition="right" 
                                labelContent={TranslatedTexts.ModelSettingsUnitM} 
                                onClick={(e, newChecked) => this.updateFileCheckBoxValue(e, idx, "BaseUnit", "m")} 
                                checked={file["BaseUnit"] == "m"} 
                                value={file["BaseUnit"] == "m"} 
                              />
                            </div>
                          </div>
                          <div className='file_data_element'>
                            <div title={TranslatedTexts.FilePickerAxis_Help}><label>{TranslatedTexts.FilePickerAxisTitle}</label></div>
                            <div style={{width: "2rem"}}>
                              <CheckboxWithLabel 
                                checkboxPosition="right" 
                                checked={!file["UpAxis"]} 
                                value={!file["UpAxis"]} 
                                labelContent="Y"
                                onClick={(e, newChecked) => this.updateFileCheckBoxValue(e, idx, "UpAxis", newChecked ? 0 : 1)}
                              />
                              <CheckboxWithLabel 
                                checkboxPosition="right" 
                                checked={file["UpAxis"]} 
                                value={file["UpAxis"]} 
                                onClick={(e, newChecked) => this.updateFileCheckBoxValue(e, idx, "UpAxis", newChecked ? 1 : 0)} 
                                labelContent="Z"
                              />
                            </div>
                          </div>
                          <div className='file_data_element'>
                            <div title={TranslatedTexts.FilePickerFwAxis_Help}><label>{TranslatedTexts.FilePickerFwAxisTitle}</label></div>
                            <div style={{width: "3rem"}}>
                              <CheckboxWithLabel 
                                checkboxPosition="right" 
                                checked={!file["ForwardAxis"]} 
                                value={!file["ForwardAxis"]} 
                                labelContent={file["UpAxis"] == 0 ? "-Z" : "-Y"}
                                onClick={(e, newChecked) => this.updateFileCheckBoxValue(e, idx, "ForwardAxis", newChecked ? 0 : 1)}
                              />
                              <CheckboxWithLabel 
                                checkboxPosition="right" 
                                checked={file["ForwardAxis"]} 
                                value={file["ForwardAxis"]} 
                                onClick={(e, newChecked) => this.updateFileCheckBoxValue(e, idx, "ForwardAxis", newChecked ? 1 : 0)} 
                                labelContent={file["UpAxis"] == 0 ? "Z" : "Y"}
                              />
                            </div>
                          </div>
                        </div>}
                      </div>
                    )})
              }
              {state.showingFileReq && 
                <div className='file_requirements_tooltip' style={{fontSize: '0.9em', lineHeight: '1.0em', padding: '0 1em 2em 1em', fontWeight: 'normal'}}>
                  {TranslatedTexts.FileReqText}
                </div>}
              {state.uploading && !state.showingFileReq && false &&
                <div id="progressbar">

                  <label>{this.getStateDesciption(state.progressBarState)}</label>
                   
                  <div id="progressbar_bar" style={{width: (state.progressBarState/6) * 100 + "%"}}></div>
                </div>}
            </div>
            {uploadButtonState == "empty" && 
                <button className='upload_button armodelviewer_button upload' disabled={!props.canEdit || !(props.spaceLeft > state.uploadingFiles.length)} title={!(props.spaceLeft > state.uploadingFiles.length) ? TranslatedTexts.ModelLimit1 : TranslatedTexts.SelectFile} onClick={(e) => {this.uploadThroughHtmlElement(e)}}>
                  {TranslatedTexts.SelectFile}
                </button>}
            {uploadButtonState == "selected" &&
                <button className='upload_button armodelviewer_button upload' disabled={!props.canEdit || !(props.spaceLeft > state.uploadingFiles.length)} title={!(props.spaceLeft > state.uploadingFiles.length) ? TranslatedTexts.ModelLimit1 : TranslatedTexts.SelectFile} onClick={(e) => {this.setState({dropZonePageIndex: state.dropZonePageIndex + 1})}}>
                  {TranslatedTexts.FilePickerNextPage}
                </button>}
            {uploadButtonState == "upload" && 
                <button className='upload_button armodelviewer_button upload' disabled={!props.canEdit || !(props.spaceLeft > state.uploadingFiles.length)} title={!(props.spaceLeft > state.uploadingFiles.length) ? TranslatedTexts.ModelLimit1 :TranslatedTexts.Upload} onClick={(e) => this.onFileUpload.bind(this)(e)}>
                  {TranslatedTexts.Upload}
                </button>}
            <input type="file" disabled={!props.canEdit || !(props.spaceLeft > state.uploadingFiles.length)} onChange={(e)=>this.onFileChange(e.target.files, e)} ref={this.fileUploadRef} hidden/>
          </div>
          {filesSelected && <div className='drop_zone_page_selection' id='drop_zone_page_selection'>
              <div className={state.dropZonePageIndex == 0 ? 'drop_zone_button_selected left_button' : 'drop_zone_button left_button'} onClick={() => this.setState({dropZonePageIndex: 0})}>
                <label>{TranslatedTexts.FilePickerPage1Title}</label>
              </div>
              <div className={state.dropZonePageIndex == 1 ? 'drop_zone_button_selected right_button' : 'drop_zone_button right_button'} onClick={() => this.setState({dropZonePageIndex: 1})}>
                	<label>{TranslatedTexts.FilePickerPage2Title}</label>
              </div>
          </div>}
        </div>
        </div>
        {
          state.uploadingFiles.length > 0 &&
          <ProgressView
            deleteModel={(itemID) => props.deleteModel(itemID)}
            files = {state.uploadingFiles}
          />
        }
      </div>
    );
  }
}

export default FilePicker;
