import React from 'react';
import { TranslatedTexts } from '../Locale/TranslatedTexts';
import WarningTriangle from '../../images/warning_triangle.png'
import VithroughPrompt from '../VithroughPopup/VithroughPopup'
import { Link } from "react-router-dom";
import './UserFileWarning.less'

class UserFileWarning extends React.Component {
    static defaultProps = {
        title: TranslatedTexts.ModelTooBigTitle,
        text: TranslatedTexts.ModelTooBigText,
        type: "none",
    };

    constructor(props) {
        super(props);

        this.state = {
            open: false,
        };
    }

    render() {
        return (
            <div className='user_file_element_warning' onMouseEnter={(e) => this.setState({open: true})} onMouseLeave={(e) => this.setState({open: false})}>
            <div className='user_file_element_warning_link'>
                <Link title={TranslatedTexts.Warning} to="/faq">
                    <img src={WarningTriangle}/>
                </Link>
            </div>
            {this.state.open && <VithroughPrompt
                title={this.props.title}
                text ={this.props.text}
                type= "none"/>}
            </div>                      
            );
    }
}

export default UserFileWarning;
