import React from 'react';
import { Uploader } from '../Uploader/Uploader';
import DeleteIcon from '../../images/delete_icon.png';
import QRDownloadIcon from '../../images/qrDownload_icon.png';
import QRShowIcon from '../../images/QRview_icon.png';
import CopyIcon from '../../images/copyLink_icon.png'
import CloseIcon from '../../images/close_icon.png';
import QRIcon from '../../images/qr-code_icon.png';
import { TranslatedTexts } from '../Locale/TranslatedTexts';
import {QRCodeSVG} from 'qrcode.react';
import SearchBar from '../SearchBar/SearchBar';
import InAppCheck from '../../images/InAppCheck.png'
import InAppCross from '../../images/InAppCross.png'
import FileElement from '../FileElement/FileElement';
import './FileList.less'

class FileList extends React.Component {

  

  fileSortTags = [
    {title: TranslatedTexts.SortTagUploadDate, 
      function: 
        function(elements){ 
          elements.sort(function(a, b){
            return new Date(b.Uploaded) - new Date(a.Uploaded)
        })
      }},
    {title: TranslatedTexts.SortTagUpdateDate, 
        function: 
          function(elements){ 
            elements.sort(function(a, b){
              return new Date(b.Updated) - new Date(a.Updated)
          })
        }},
    {title: TranslatedTexts.SortTagName, 
      function: 
        function(elements){ 
          elements.sort((a, b) => {
            let nameA = a.Description.toLowerCase();
            let nameB = b.Description.toLowerCase();
            if (nameA < nameB)
              return -1;
            if (nameA > nameB)
              return 1;
            return 0;
        })
      }},
    {title: TranslatedTexts.SortTagInApp, 
      function: 
        function(elements){ 
          elements.sort(function(a, b){
            return (a.InApp === b.InApp)? 0 : a.InApp? -1 : 1;
          })
      }},
    {title: TranslatedTexts.SortTagSize, 
      function: 
        function(elements){ 
          elements.sort(function(a, b){
            return b.RawSize - a.RawSize;
          })
      }}
  ]

    static defaultProps = {
      realm: null,
      companyID: null,
      fileSpaces: 5,
      appAccess: false,
      canEdit: false,
      copyLink: function(){},
      onShowQRCode: function(){},
      onUpdateQRCode: function(){},
      onClickOpenModel: function(){},
      onFilesChanged: function(){},
      deleteModel: function(){},
      userFilesData: [],
      modelWarningSize: 1000000, //bytes //=10mb
    };

    closeNoticeTimeout = null;

    constructor(props) {
        super(props);
        let browserWidth = this.getWindowWidth();
        let searchOptions = {sortTag: this.fileSortTags[0], filterTag: null, inverted: false};

        this.state = {
          fileSpaces: this.props.fileSpaces,
          appAccess: this.props.appAccess,
          modelsOverlayOpen: false,
          modelviewerModel: null,
          activeQrCode: null,
          editModelName: -1,
          tutorialOpen: false,
          debugItem: null, //if ItemID is set here, only the specified element will be shown in list
          noticeSustain: 1500,
          showNotice: false,
          filesSearchOptions: searchOptions,
          browserWidth: browserWidth,
          tempFileName: "",
          filesToRender: this.getRenderFiles(this.props.fileSpaces, searchOptions, browserWidth, null),
          modelWarningSize: this.props.modelWarningSize,
          userFilesData: this.props.userFilesData,          
        };
    }

    componentDidMount(){
      window.addEventListener('resize', () => this.setState({browserWidth: this.getWindowWidth()}) , true);
      this.setState({filesToRender: this.getFilesToRender()});
    }

    setEditModelname(idx, newname =""){
        if (!this.props.canEdit) return;
        const filesToRender = this.getRenderFiles(this.state.fileSpaces, this.state.filesSearchOptions, this.state.browserWidth, null);
        if (newname != ""){
          filesToRender[this.state.editModelName].Description = newname;        
          Uploader.updateJobDescription(filesToRender[this.state.editModelName].ItemID, newname);
        }

        var tempname = "";
        if (filesToRender && filesToRender[idx])
          tempname = filesToRender[idx].Description;
        this.setState({editModelName: idx, tempFileName: tempname});
    }  

    componentDidUpdate(prevProps, prevState){      
      if (prevProps.fileSpaces !== this.props.fileSpaces || prevProps.appAccess !== this.props.appAccess)
      {
        var newlimit = this.props.fileSpaces;        
        this.setState({userFilesData: this.props.userFilesData, fileSpaces: newlimit, appAccess: this.props.appAccess});
      }

      else if(prevState.filesSearchOptions != this.state.filesSearchOptions){
        this.setState({userFilesData: this.props.userFilesData})
      }

      else if(prevProps.userFilesData != this.props.userFilesData){
        this.setState({userFilesData: this.props.userFilesData})
      }

      else
      {
        var equal = true;
        this.props.userFilesData.forEach((e) => 
          {
            if (this.state.userFilesData == null) 
              {
                equal = false;              
                return;
              }
            var finding = this.state.userFilesData.find((f) => e.ItemID == f.ItemID);            
            if (finding == null)
            {

              equal = false;
              return;
            }            
          })        
          
        if (!equal)
          {                        
              this.setState({userFilesData: this.props.userFilesData})              
          }
      }
    }

    setCompany(companyID){
      //open new browser tab
      window.open(this.generateUserLink(companyID)).focus();      
    }

    generateModelLink(id){
      if(id){        
        if (window.config.host.cdn.includes("vithrough"))
          return "https://viewer.vithrough.com/?id=" + id;
        else
        return window.config.host.cdn + "/" + this.props.realm + "/modelviewer/?id=" + id;
      }else{
        return null
      }
    }

    generateUserLink(id){
      if(id){
        if (window.config.host.cdn.includes("vithrough"))
          return "https://viewer.vithrough.com/?userID=" + id;
        else
        return window.config.host.cdn + "/" + this.props.realm + "/modelviewer/?userID=" + id;
      }else{
        return null
      }
    }

    downloadCompanyQRCode(companyID){
      this.props.onUpdateQRCode(this.generateUserLink(companyID), "models_qr.png");
    }

    downloadModelQRCode(itemID, filename){
      this.props.onUpdateQRCode(this.generateModelLink(itemID), filename);
    }

    updateInAppForModel(ItemID, newVal){
      Uploader.updateJobInApp(ItemID, newVal)
      .then((res)=>{
        this.props.onFilesChanged(); 
      })
      .catch((err)=>{})      
    }    

    updateInAppForUser(newVal){
        let filteredFiles = this.getFilteredFiles(this.state.userFilesData, this.state.filesSearchOptions).map(e => e.ItemID);
        Uploader.updateInAppForUser(this.props.companyID, newVal, filteredFiles)
        .then((res)=>{
          this.props.onFilesChanged();
        })
        .catch((err)=>{})    
    } 

    getFilteredFiles(files, searchOptions){
      if(searchOptions && searchOptions.filterTag && searchOptions.filterTag.function){
        return searchOptions.filterTag.function(files, searchOptions.filterTag.searchText);
      }

      return files;
    }
    
    getRenderFiles(fileSpaces, filesSearchOptions, browserWidth, debugItem){
      let filesToRender = []
        
        if(!this.state || !this.state.userFilesData){
            return filesToRender;
        }
        
        for (let index = 0; index < fileSpaces; index++) {
            if(this.state.userFilesData[index]){
              filesToRender.push(this.state.userFilesData[index]);
            }
        }
        
        let searchOptions = filesSearchOptions;
        if(searchOptions){
            // --> filter files
            if(searchOptions.filterTag){
              filesToRender = this.getFilteredFiles(filesToRender, searchOptions);
            }
            // --> sort files
            if(searchOptions.sortTag){
            searchOptions.sortTag.function(filesToRender);
              if(searchOptions.inverted){
                  filesToRender.reverse(); 
              }
            }
        }

        const userFilesLength = this.state.userFilesData.length;
        if(fileSpaces > userFilesLength){
            // --> append empty file spaces
            if(userFilesLength % 2 == 0 && (browserWidth > 1450 /*NOTE: Value taken from css media queries ans must be kept equal*/)){
              filesToRender = filesToRender.concat([{}, {}])
            }else{
              filesToRender.push({})
            }
        }else{
            // --> add "do you need more space?" element
            filesToRender.push({ItemID: -1}); 
        }
        
        if(debugItem){
            filesToRender.length = 0;
            if(this.state.userFilesData){
              this.state.userFilesData.forEach(file => {
                  if(file.ItemID && file.ItemID == debugItem){
                  filesToRender.push(file);
                  }
              });
            }
        }
      
      return filesToRender;
    }
    
    getFilesToRender(){
      return this.getRenderFiles(this.state.fileSpaces, this.state.filesSearchOptions, this.state.browserWidth, this.state.debugItem); 
    }

    getWindowWidth(){
      if(document){
        if(document.body && document.body.offsetWidth){
          return document.body.offsetWidth;
        }

        if(document.documentElement && document.documentElement.offsetWidth){
          return document.documentElement.offsetWidth;
        }
      }

      if(window && window.innerWidth){
        return window.innerWidth;
      }

      return -1;
    }

    render() {
      const userFilesCount = this.props.userFilesData ? this.props.userFilesData.length : 0;
      const allFiles = this.props.userFilesData ? this.props.userFilesData : [];
      
      const filesSearchOptions = this.state.filesSearchOptions;
      const filesToRender = this.getRenderFiles(this.props.fileSpaces, filesSearchOptions, this.state.browserWidth, null);
      const filteredFiles = this.getFilteredFiles(allFiles, filesSearchOptions);
      const filterActive = filesSearchOptions && filesSearchOptions.filterTag && filesSearchOptions.filterTag.searchText != "" && filesSearchOptions.filterTag.searchText != null;
      var allFilesNotInApp = filteredFiles.find((e) => e.InApp == true) == null;
      var allFilesInApp = filteredFiles.find((e) => e.InApp == false) == null;
      return (
        <div className='user_files_container'>
            <div className='user_files_list_header'>
                <div className='user_files_list_header_left'>
                <div id='user_list_circle' className='user_list_circle'/>
                <label id='Models'>{TranslatedTexts.YourModels}</label>
                </div>
                {this.state.appAccess == "1" && this.props.canEdit &&
                <div className='user_files_list_header_right' id="user_files_list_header_center">
                    <button title={filterActive ? TranslatedTexts.SelectedModelsInAppHover : TranslatedTexts.AllModelsInAppHover} disabled={allFilesInApp} id='view_all' className='armodelviewer_button' onClick={() =>  this.updateInAppForUser(true)}>
                    <img src={InAppCheck}/>{filterActive ? TranslatedTexts.SelectedModelsInApp : TranslatedTexts.AllModelsInApp}</button>
                    <button title={filterActive ? TranslatedTexts.SelectedModelsOutAppHover : TranslatedTexts.AllModelsOutAppHover} disabled={allFilesNotInApp} id='view_all' className='armodelviewer_button' onClick={() => this.updateInAppForUser(false)}>
                    <img src={InAppCross}/>{filterActive ? TranslatedTexts.SelectedModelsOutApp : TranslatedTexts.AllModelsOutApp}</button>
                </div>}
                <div className='user_files_list_header_right'>
                <label id='Space'>{userFilesCount + "/" + this.state.fileSpaces + " " + TranslatedTexts.SpacesTaken}</label>
                <button title={TranslatedTexts.ViewQRCode} id='view_all' disabled={filterActive} className='armodelviewer_button' onClick={() => {this.setState({modelsOverlayOpen: !this.state.modelsOverlayOpen})}}>
                    <img src={QRIcon}/>{TranslatedTexts.AllModels}
                </button>
                {this.state.modelsOverlayOpen && 
                    <div className="all_files_popup">
                    <label className='all_models_info'>{TranslatedTexts.AllModelsInfo}</label>
                    <button title={TranslatedTexts.Close} className='close_button' onClick={() => this.setState({modelsOverlayOpen: false})}><img src={CloseIcon}/></button>
                    <div className='all_models_container'>
                        <div className='qr_code_container'>
                        <QRCodeSVG className='qr_code' id='company_qr_code' value={this.generateUserLink(this.props.companyID)}/>
                        </div>
                        <div className='button_column'>
                        <button onClick={() => this.setCompany(this.props.companyID)} title={TranslatedTexts.OpenWebsite} className='armodelviewer_button'>{TranslatedTexts.OpenWebsite}</button>
                        <button onClick={()=>this.downloadCompanyQRCode(this.props.companyID)} title={TranslatedTexts.DownloadImage} className='armodelviewer_button'>{TranslatedTexts.DownloadImage}</button>
                        </div>
                    </div>                        
                    </div>}
                </div>
            </div>
            <div className='user_files_list_container'>
                <SearchBar
                onNewSearchOptions={(options) => {this.setState({filesSearchOptions: options})}}
                sortTags={this.fileSortTags}
                filterTags={[
                    {title: TranslatedTexts.FilterTagName,  
                    function: 
                        function(elements, filterText){ 
                        return elements.filter((elem) => {
                            if(filterText == null || filterText == ""){
                                return true; //if there is nothing to filter against
                            }

                            return elem && elem.FileName && filterText && elem.FileName.toLowerCase().indexOf(filterText.toLowerCase()) >= 0;
                        })
                    }},
                ]}
                />
                <div className='user_files_list' id='user_files_list'>
                {userFilesCount > 0 ? 
                filesToRender.map((data, idx) => {
                    if(data.ItemID){
                      const itemID = data.ItemID;
                        
                      if(itemID != -1){
                        const fileTooBig = data.GltfSize > this.state.modelWarningSize || data.UsdzSize > this.state.modelWarningSize;
                        return (
                          <FileElement
                            data = {data}
                            editModelName = {this.state.editModelName} //TODO: editModelName kann wahrscheinlich auch direkt im Element stattfinden
                            fileTooBig = {fileTooBig}
                            setEditModelname = {(idx, name) => {this.setEditModelname(idx, name)}}
                            onClick = {(data) => {this.setState({tempFileName : "", editModelName: -1}); this.props.onClickOpenModel(data)}}
                            appAccess = {this.state.appAccess}
                            canEdit = {this.props.canEdit}
                            tempFileName = {this.state.tempFileName}
                            onChangeTempFileName= {(value) => {this.setState({tempFileName: value})}}
                            copyLink = {() => this.props.copyLink(itemID)}
                            onShowQRCode = {() => this.props.onShowQRCode(this.generateModelLink(itemID))}
                            deleteModel = {() => this.props.deleteModel(itemID)}
                            downloadModelQRCode = {() => this.downloadModelQRCode(itemID, data.Description + "_qr.png")}
                            updateInAppForModel = {(checked) => this.updateInAppForModel(itemID, checked)}
                            fileIndex= {idx}
                            key={"UploadedFileData-" + idx}
                          />)
                        }else{
                          return(
                              filesToRender.length - userFilesCount < 3 &&
                              <div className='user_file_element_getMoreSpace'  key={"UploadedFileData-" + idx}>
                              <label id='more_space'>{TranslatedTexts.MoreSpace}</label>
                              </div>
                          )
                        }
                    }else{
                        return( 
                        userFilesCount + 2 > idx &&
                        <div className='user_file_element_empty'  key={"UploadedFileData-" + idx}>
                            <label>{TranslatedTexts.EmptySlot}</label>
                        </div>
                        )
                    }
                })
                :<label>{TranslatedTexts.NoModel}</label>}
                </div>
            </div>
        </div>
        );
    }
}

export default FileList;
