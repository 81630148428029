import React from 'react';
import PolygonIcon from '../../images/polygon_icon.png'
import InvertDownIcon from '../../images/invert_down_icon.png'
import InvertUpIcon from '../../images/invert_up_icon.png'
import MagnifierIcon from '../../images/magnifier_icon.png'
import XIcon from '../../images/searchbar_delete.png'
import XIconHover from '../../images/searchbar_delete_hover.png'
import './SearchBar.less'
import { TranslatedTexts } from '../Locale/TranslatedTexts';

class SearchBar extends React.Component {
    static defaultProps = {
        sortTags: [{title: "Option", function: function(elements){ return elements;}}],
        filterTags: [{title: "Option", function: function(elements){ return elements;}}],
        onNewSortTag: function(){},
        onInvertSort: function(){},
        onNewFilterTag: function(){},
        onNewSearchOptions: function(){},
    };

    constructor(props) {
        super(props);

        this.state = {
            dropDownOpen: false,
            curSortTag: this.props.sortTags[0],
            curFilterTag: null,
            curSearchOptions: {sortTag: {title: "Option", function: function(){}}, filterTag: {title: "Option", function: function(){}}, inverted: false},
            inverted: false,
            xHovered: false,
        };
    }

    setInvertSort(newInverted){
        let newSearchOptions = {sortTag: this.state.curSortTag, filterTag: this.state.curFilterTag, inverted: newInverted};
        this.props.onInvertSort(newInverted);
        this.props.onNewSearchOptions(newSearchOptions);
        this.setState({inverted: newInverted, dropDownOpen: false, curSearchOptions: newSearchOptions});
    }

    setSortTag(newTag){
        let newSearchOptions = {sortTag: newTag, filterTag: this.state.curFilterTag, inverted: this.state.inverted};
        this.props.onNewSortTag(newTag);
        this.props.onNewSearchOptions(newSearchOptions);
        this.setState({dropDownOpen: false, curSortTag:newTag, curSearchOptions: newSearchOptions}); 
    }

    setSearchFilter(e, filterTag){
        let searchText;
        if(filterTag == null){
            searchText = "";
        }else{
           searchText = e.target.value
           filterTag.searchText = searchText;
        }

        let newSearchOptions = {sortTag: this.state.curSortTag, filterTag: filterTag, inverted: this.state.inverted};
        this.props.onNewFilterTag(filterTag);
        this.props.onNewSearchOptions(newSearchOptions);
        this.setState({dropDownOpen: false, curFilterTag: filterTag, curSearchOptions: newSearchOptions}); 
    }

    setXHovered(newActive){
        this.setState({xHovered: newActive})
    }

    render() {    
        const state = this.state;
        const props = this.props;
        const filterTag = this.state.curFilterTag;
        const xIcon =  this.state.xHovered ? XIconHover : XIcon;

        return (
        <div className='searchbar'>
            <div className='sort'>
                <div className='sort_dropdown_container' onMouseLeave={() => this.setState({dropDownOpen: false})}>
                    <button 
                        className={"armodelviewer_button " + (state.dropDownOpen ? 'sort_dropdown_button_open' : 'sort_dropdown_button')} 
                        onClick={() => this.setState({dropDownOpen: !state.dropDownOpen})}
                        title={TranslatedTexts.SortAccordingTo}>
                            {TranslatedTexts.SortAccordingTo + "    " + (state.curSortTag ? state.curSortTag.title : "") + "        "}
                            <img src={PolygonIcon} className={state.dropDownOpen ? "polygon_opened" : "polygon_closed"}/>
                    </button>
                    <div className='sort_dropdown' style={{visibility: state.dropDownOpen ? "visible" : "hidden"}}>
                        {props.sortTags.map((tag, idx) => {  
                            return <div className='sort_dropdown_element' key={'sort_dropdown_element' + idx}>
                                <button onClick={() => {this.setSortTag(tag)}} title={TranslatedTexts.SortAccordingTo + " " + tag.title}>
                                    <label>{tag.title ? tag.title : ""}</label>
                                </button>
                            </div>
                        })}
                    </div>
                </div>
                <button 
                    className='sort_dropdown_invert_button armodelviewer_button' 
                    onClick={() => this.setInvertSort(!state.inverted)}
                    title={TranslatedTexts.SortInvertButton}>
                    <img src={state.inverted ? InvertUpIcon : InvertDownIcon}/>
                </button>
            </div>
            <div className='search'>
                {props.filterTags.map((tag, idx) => {  
                        return <div className='filter_input' key={'sort_dropdown_element' + idx}>
                                <input type='text' value={filterTag && filterTag.searchText ? filterTag.searchText : ""} onChange={(e) => this.setSearchFilter(e, tag)}></input>
                                <button 
                                    className='filter_delete_button' 
                                    onMouseEnter={() => this.setXHovered(true)} 
                                    onMouseLeave={()=> this.setXHovered(false)} 
                                    onClick={(e) => this.setSearchFilter(e, null)}>
                                        <img src={filterTag && filterTag.searchText ? xIcon : MagnifierIcon}/>
                                </button>
                        </div>
                    })}
            </div>
        </div>
        );
    }
}

export default SearchBar;
