import React from 'react';
import { Uploader } from '../Uploader/Uploader';
import DeleteIcon from '../../images/delete_icon.png';
import QRDownloadIcon from '../../images/qrDownload_icon.png';
import QRShowIcon from '../../images/QRview_icon.png';
import CopyIcon from '../../images/copyLink_icon.png'
import CloseIcon from '../../images/close_icon.png';
import QRIcon from '../../images/qr-code_icon.png';
import { TranslatedTexts } from '../Locale/TranslatedTexts';
import {QRCodeSVG} from 'qrcode.react';
import SearchBar from '../SearchBar/SearchBar';
import InAppCheck from '../../images/InAppCheck.png'
import InAppCross from '../../images/InAppCross.png'
import WarningTriangle from '../../images/warning_triangle.png'
import VithroughPrompt from '../VithroughPopup/VithroughPopup'
import UserFileWarning from '../UserFileWarning/UserFileWarning';
import { Link } from "react-router-dom";
import './FileElement.less'

class FileElement extends React.Component {
    static defaultProps = {
        data: null,
        editModelName: false,
        fileTooBig: false,
        setEditModelname: function(){},
        onClick: function(){},
        appAccess: false,
        canEdit: false,
        tempFileName: "",
        onChangeTempFileName: function(){},
        copyLink: function(){},
        onShowQRCode: function(){},
        deleteModel: function(){},
        downloadModelQRCode: function(){},
        updateInAppForModel: function(){},
        fileIndex: -1,
    };

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    generateWarningMessage(data, appAccess){
        if(data){
            let rapid = data.Rapid;
    
            if(rapid){
                if(appAccess){
                    //has rapid and app
                    return {title: TranslatedTexts.ModelTooBigRapidAppTitle, text: TranslatedTexts.ModelTooBigRapidAppText}
                }else{
                    // has rapid only
                    return {title: TranslatedTexts.ModelTooBigRapidTitle, text: TranslatedTexts.ModelTooBigRapidText}
                }
            }else if(appAccess){
                //has app only
                return {title: TranslatedTexts.ModelTooBigAppTitle, text: TranslatedTexts.ModelTooBigAppText}
            }
        }

        //has nothing
        return {title: TranslatedTexts.ModelTooBigTitle, text: TranslatedTexts.ModelTooBigText}
    }

    render() {
        const props = this.props;
        const data = props.data;
        const fileTooBig = props.fileTooBig;
        const idx = props.fileIndex
        const warningMessage = this.generateWarningMessage(data, this.props.appAccess);
        
        return (
        <div className={fileTooBig ? 'user_file_element_filesize_warning' : 'user_file_element'}>
            <div className='user_file_element_section'>
                {props.editModelName != idx &&
                    <label onClick={() => {props.setEditModelname(idx)}}>{data.Description}</label>
                }
                {props.editModelName == idx &&                                      
                    <input 
                    type="text" 
                    autoFocus 
                    onKeyDown={(e) => {if(e.keyCode == 13){
                        props.setEditModelname(-1, props.tempFileName);
                    }}} 
                    onBlur={() => props.setEditModelname(-1, props.tempFileName)}
                    value={props.tempFileName}
                    onChange={(e) => props.onChangeTempFileName(e.target.value)}
                    />                          
                }
                
                <label className='small_text'>{"(" + (new Date(data.Updated) == null ? "" : new Date(data.Updated).toLocaleDateString("de-DE")) + " " + TranslatedTexts.ModelUpdated + ")"}</label>
                <div className='user_file_element_row'>
                    <div className='user_file_element_row_element'>
                    <label>{TranslatedTexts.ModelLink}</label>
                    <button style={{padding: "0.3em 0.3em 0.1em 0.3em"}} title={TranslatedTexts.ModelLinkTitle} onClick={() => props.copyLink()}><img src={CopyIcon}></img></button>
                    </div>
                    <div className='user_file_element_row_element'>
                    <label>{TranslatedTexts.QRCode}</label>
                    <button style={{padding: "0.3em 0.3em 0.1em 0.3em"}} title={TranslatedTexts.ViewQRCode} onClick={() => props.onShowQRCode()}><img src={QRShowIcon}></img></button>
                    <button style={{padding: "0.3em 0.3em 0.1em 0.3em"}} title={TranslatedTexts.Download} onClick={() => props.downloadModelQRCode()}><img src={QRDownloadIcon}></img></button>
                </div>
                </div>
            </div>
            <div className='user_file_element_section_right'>
                <div className={(props.appAccess == "1" && props.canEdit) ? 'user_file_element_section_top_edit' : 'user_file_element_section_top'}>
                    {props.appAccess == "1" && props.canEdit &&
                        <div className='user_file_element_section_input'><p>In App:  </p><label className="switch">
                        <input checked={data.InApp} onChange={(e) => props.updateInAppForModel(e.target.checked)} type="checkbox"></input>
                        <span className="slider round"></span>
                        </label>
                        </div>
                    }
                    <button className='section_delete' disabled={!props.canEdit} title={TranslatedTexts.Delete} onClick={() => props.deleteModel()}><img src={DeleteIcon}></img></button>
                </div>
                <div className='user_file_element_section_bottom'>
                    {fileTooBig && 
                        <UserFileWarning 
                            title={warningMessage.title}
                            text={warningMessage.text}
                        />}
                    <button title={TranslatedTexts.OpenFile} onClick={() => {props.onClick(props.data)/*this.setState({tempFileName : "", editModelName: false}); this.props.onClickOpenModel(data)}*/}} className='armodelviewer_button armodelviewer_button_fileopen'>{TranslatedTexts.OpenFile}</button>
                </div>
            </div>
        </div>
        )
    }
}

export default FileElement;
