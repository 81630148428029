
import React from 'react';
import './CheckboxWithLabel.less'
class CheckboxWithLabel extends React.Component {

    static defaultProps = {
        onClick: function(){},
        labelOnClick: function(){},
        checked: false,
        value: 0,
        onChange: function(){},
        labelContent: "",
        checkboxPosition: "left",
        style: null,
        disabled: false
    };

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {        
        return (
        <div className={this.props.disabled ? 'checkbox_with_label checkbox_with_label_disabled' : 'checkbox_with_label'} onClick={(e) => {if (this.props.disabled) return; this.props.onClick(e, !this.props.checked)}} style={this.props.style}>
            {this.props.checkboxPosition == "left" &&
            <div className='checkbox_container'>
                <input disabled={this.props.disabled} type="checkbox" value={this.props.value} readOnly checked={this.props.checked}  /*onChange={(e) => {this.props.onChange(e)}}*/></input>
                <span className="checkmark"></span>
            </div> }
            <label className='file_data_element_option' onClick={(e) => this.props.labelOnClick(e)}>{this.props.labelContent}</label>
            {this.props.checkboxPosition == "right" &&
            <div className='checkbox_container'>
                <input disabled={this.props.disabled} type="checkbox" value={this.props.value} readOnly checked={this.props.checked} /*onChange={(e) => {this.props.onChange(e)}}*/></input>
                <span className="checkmark"></span>
            </div> }
        </div>)
    };
}
export default CheckboxWithLabel;
