import React from 'react';
import './Dropdown.less';
import PolygonIcon from '../../images/polygon_icon.png'

class Dropdown extends React.Component {
    static defaultProps = {
        title:  "Dropdown",
        selections: [{text: "Option", value: 1}],
        onSelected: function(){},
        onChange: function(){},
        value: "",
    };

    constructor(props) {
        super(props);
        this.state = {
            dropDownOpen: false,
            selectedIndex: this.getSelectedIndex(this.props.selections, this.props.value),
        };        
    }

    getSelectedIndex(selections, value){
        selections.forEach((tag, index) => {
            if(tag.value == value){
                return index;
            }
        });

        return 0;
    }
    
    setTag(newTag, idx){
        if(newTag != null){
            this.setState({dropDownOpen: false, selectedIndex: idx}); 
            this.props.onChange(newTag.value);
        }
    }

    render() {
        const state = this.state;
        const props = this.props;

        return (
            <div className={this.props.disabled ? "vithrough_dropdown disabled" : "vithrough_dropdown"}>
                <div className='vithrough_dropdown_container' onMouseLeave={() => this.setState({dropDownOpen: false})}>
                    <button 
                        disabled={this.props.disabled}
                        className={state.dropDownOpen ? "armodelviewer_button vithrough_dropdown_button_open" : "armodelviewer_button vithrough_dropdown_button"} 
                        onClick={() => this.setState({dropDownOpen: !state.dropDownOpen})}
                        title={this.props.title}>
                            <label>{props.selections && props.selections[state.selectedIndex] ? props.selections[state.selectedIndex].text : ""}</label>
                            <img src={PolygonIcon} className={state.dropDownOpen ? "vithrough_polygon_opened" : "vithrough_polygon_closed"}/>
                    </button>
                    <div className='vithrough_dropdown_elements' style={{visibility: state.dropDownOpen ? "visible" : "hidden"}}>
                        {props.selections.map((tag, idx) => {  
                            return  <div className='vithrough_dropdown_element' key={'dropdown_element' + idx}>
                                        <button 
                                        onClick={() => this.setTag(tag, idx)} 
                                        title={tag.text ? tag.text : ""}
                                        disabled={this.props.disabled}>
                                            <label>{tag.text}</label>
                                        </button>
                                    </div>
                        })}
                    </div>
                </div>
            </div>
        );            
    }
}

export default Dropdown;
