
import axios from 'axios';

export const Uploader = {
    addJob: function(realm, uploadPath, userID, targetFormat, filename, keepOffset = "0", scale = "1", position = "0", animate = "0", backgroundColor="#eeeeee", baseUnit = "m", upAxis = 0, forwardAxis = 0){
        return new Promise(function(resolve, reject){

        if(!realm || !uploadPath || !userID || !targetFormat){
            reject();
        }
        
        const formData = new FormData();
        formData.append("RealmHash", realm);                
        formData.append("UploadPath", uploadPath); //"/Files"
        formData.append("UserID", userID);
        formData.append("TargetFormat", targetFormat);
        formData.append("FileName", filename);
        formData.append("KeepOffset", keepOffset);
        formData.append("Scale", scale);
        formData.append("Position", position);
        formData.append("Animate", animate);
        formData.append("BackgroundColor", backgroundColor);
        formData.append("BaseUnit", baseUnit);
        formData.append("UpAxis", upAxis);
        formData.append("ForwardAxis", forwardAxis);
    
        // Request made to the backend api
        // Send formData object
        axios.post(window.config.vendor.api + "/arautomation/addJob", formData)
        .then(function (response) {                
            const data = response.data;
            if(data.Success){                
                resolve(data);
            }else{                
                reject(data["0"]);
            }
        })
        .catch((err) => {
            reject(err);
        })
    })},

    uploadFile: function(realm, selectedFile, uploadPath, userID, itemID, updateValue = function(val){}){
        return new Promise(function(resolve, reject){

        if(!realm || !selectedFile || !uploadPath || !userID ||!itemID){
            reject();
        }        
        const formData = new FormData();
        formData.append("RealmHash", realm);
        formData.append("File", selectedFile);
        formData.append("FileName", selectedFile.name.split(".")[0]);
        formData.append("FileType", "." + selectedFile.name.split(".")[1].toLowerCase());
        formData.append("UploadPath", uploadPath);
        formData.append("UserID", userID);
        formData.append("ItemID", itemID);        
        const config = {
            onUploadProgress: progressEvent => updateValue(progressEvent.loaded / progressEvent.total, itemID),
            headers: { 'Content-Type': 'multipart/form-data;charset=utf-8' },
        }
        //unfinished url with progress:             
        axios.post(window.config.host.uploadurl, formData, config)
        //axios.post(window.config.vendor.api + "/arautomation/uploadFileForJob", formData)
        .then(function (response) {
            const data = response.data;
            if(data.Success){
                resolve(data);
            }else{
                reject(data);
            }
        })
        .catch((err) => {
            reject(err);
        })
    })},

    getPendingJobs: function(){
        return new Promise(function(resolve, reject){
        axios.get(window.config.vendor.api + "/arautomation/getPendingJobs")
        .then(function (response) {            
            const data = response.data;
            if(data.Success){
                resolve(data);
            }else{
                reject(data);
            }
      }).catch((err) =>{
        reject(err);
      })
    })},

    getJob: function(itemID){
        return new Promise(function(resolve, reject){

        if(!itemID){
            reject();
        }

        axios.get(window.config.vendor.api + "/arautomation/getJob?ItemID=" + itemID)
        .then(function (response) {
            const data = response.data;
            if(data.Success){
                resolve(data);
            }else{
                reject(data);
            }
        })
        .catch((err) => {
            console.error(err);
            reject(err);
        })
    })},

    remJob: function(itemID){
        return new Promise(function(resolve, reject){

        if(!itemID){
            reject();
        }
        const formData = new FormData();
        formData.append("ItemID", itemID);
        axios.post(window.config.vendor.api + "/arautomation/remJob",formData)
        .then(function (response) {
            const data = response.data;
            if(data.Success){
                resolve(data);
            }else{
                reject(data);
            }
        })
        .catch((err) => {
            console.error(err);
            reject(err);
        })
    })},

    updateJobDescription: function(itemID, description){
        return new Promise(function(resolve, reject){

        if(!itemID || !description){
            reject();
        }
        const formData = new FormData();
        formData.append("ItemID", itemID);
        formData.append("Description", description);
        axios.post(window.config.vendor.api + "/arautomation/setJobDescription",formData)
        .then(function (response) {
            const data = response.data;
            if(data.Success){
                resolve(data);
            }else{
                reject(data);
            }
        })
        .catch((err) => {
            console.error(err);
            reject(err);
        })
    })},
    updateJobInApp: function(itemID, inApp){
        return new Promise(function(resolve, reject){

        if(!itemID){            
            reject();
        }
        const formData = new FormData();
        formData.append("ItemID", itemID);
        formData.append("InApp", inApp ? "1" : "0");
        axios.post(window.config.vendor.api + "/arautomation/updateAppaccessForJob",formData)
        .then(function (response) {
            const data = response.data;
            if(data.Success){
                resolve(data);
            }else{                
                reject(data);
            }
        })
        .catch((err) => {            
            console.error(err);
            reject(err);
        })
    })},
    updateInAppForUser: function(userID, inApp, itemIDs){
        return new Promise(function(resolve, reject){

        if(!userID || !itemIDs || itemIDs.length == 0){            
            reject();
        }
        const idString = JSON.stringify(itemIDs);
        const formData = new FormData();
        formData.append("UserID", userID);
        formData.append("InApp", inApp ? "1" : "0");
        formData.append("ItemIDs", idString);
        axios.post(window.config.vendor.api + "/arautomation/updateAppaccessForUser", formData)
        .then(function (response) {
            const data = response.data;
            if(data.Success){
                resolve(data);
            }else{                
                reject(data);
            }
        })
        .catch((err) => {            
            console.error(err);
            reject(err);
        })
    })},
    updateJobParameter: function(itemID, animate = 0, position = 0, backgroundColor = "#eeeeee"){
        return new Promise(function(resolve, reject){
            
            if(!itemID){
                reject("no item id!");
            }

            const formData = new FormData();
            formData.append("ItemID", itemID);
            formData.append("Animate", animate);
            formData.append("Position", position);
            formData.append("BackgroundColor", backgroundColor);
            
            axios.post(window.config.vendor.api + "/arautomation/updateJobParameter", formData)
            .then(function (response) {
                const data = response.data;
                if(data.Success){
                    resolve(data);
                }else{
                    reject(data);
                }
            })
            .catch((err) => {
                console.error(err);
                reject(err);
            })
    })},

    reconvertModel: function(itemID, upAxis = 0, forwardAxis = 0, baseUnit = "m", scale = 1.0){
        return new Promise(function(resolve, reject){
            
            if(!itemID){
                reject("no item id!");
            }

            const formData = new FormData();
            formData.append("ItemID", itemID);
            formData.append("UpAxis", upAxis);
            formData.append("ForwardAxis", forwardAxis);            
            formData.append("BaseUnit", baseUnit);
            formData.append("Scale", scale);
            
            axios.post(window.config.vendor.api + "/arautomation/reconvertModel", formData)
            .then(function (response) {
                const data = response.data;
                if(data.Success){
                    resolve(data);
                }else{
                    reject(data);
                }
            })
            .catch((err) => {
                console.error(err);
                reject(err);
            })
    })},

    getOngoingJobs: function(userID){
        return new Promise(function(resolve, reject){

            if(!userID){
                reject("invalid Parameter");
            }
    
            axios.get(window.config.vendor.api + "/arautomation/getOngoingJobs?UserID=" + userID)
            .then(function (response) {
                const data = response.data;
                if(data.Success){
                    resolve(data);
                }else{
                    reject(data);
                }
            })
            .catch((err) => {
                console.error(err);
                reject(err);
            })
        })},
    getJobState: function(itemID, userID){
        return new Promise(function(resolve, reject){

            if(!itemID || !userID){
                reject("invalid Parameter");
            }
    
            axios.get(window.config.vendor.api + "/arautomation/getJobState?UserID=" + userID  + "&ItemID=" + itemID)
            .then(function (response) {
                const data = response.data;
                if(data.Success){
                    resolve(data);
                }else{
                    reject(data);
                }
            })
            .catch((err) => {
                console.error(err);
                reject(err);
            })
        })},

    getJobsForUser: function(realm, userID){
        return new Promise(function(resolve, reject){

        if(!realm || !userID){
            reject();
        }

        axios.get(window.config.vendor.api + "/arautomation/getJobsForUser?UserID=" + userID + "&RealmHash=" + realm)
        .then(function (response) {
            const data = response.data;
            if(data.Success){
                resolve(data);
            }else{
                reject(data);
            }
        })
        .catch((err) => {
            console.error(err);
            reject(err);
        })
    })},

    getFileLimit: function(realm, companyID){
        return new Promise(function(resolve, reject){

        if(!realm || !companyID){
            reject();
        }

        axios.get(window.config.vendor.api + "/modelviewer/getLimit?companyid=" + companyID + "&RealmHash=" + realm)
        .then(function (response) {
            const data = response.data;
            if(data.Success){
                resolve(data);
            }else{
                reject(data);
            }
        })
        .catch((err) => {
            console.error(err);
            reject(err);
        })
    })},
    getCoworkers: function(realm, userID){
        return new Promise(function(resolve, reject){

        if(!realm || !userID){
            reject("unset values");
        }

        axios.get(window.config.vendor.api + "/modelviewer/getCoworkers?companyid=" + userID + "&RealmHash=" + realm)
        .then(function (response) {
            const data = response.data;
            if(data.Success){
                resolve(data);
            }else{
                reject(data);
            }
        })
        .catch((err) => {
            console.error(err);
            reject(err);
        })
    })}
}

