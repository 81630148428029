
import React from 'react';
import '../MainPage/MainPage.less'
import './faq.less'
import TranslatedComponent from '../Locale/TranslatedComponent';
import { TranslatedTexts } from '../Locale/TranslatedTexts';
import FAQCheck from '../../images/faq_check.png'
import FAQWarning from '../../images/faq_warning.png'
import FAQX from '../../images/faq_X.png'
import BackButton from '../../images/back_icon.png'

class FAQ extends React.Component {
    static defaultProps = {
        goToMain: function(){},
    }
    
    constructor(props) {
        super(props);

        this.state = {
            checks: [true,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false],
            goToMain: this.props.goToMain
        };

        if (props.register) {
            props.register(this);
        }
    }

    componentDidMount()
    {

    }

    changeCheck(idx)
    {
        var newchecks = Array(18).fill(false);;
        if (!this.state.checks[idx])
        {            
            newchecks[idx] = true;
        }                
        this.setState({checks: newchecks});
    }

    render() {        
        return (
            <div className='main_page faq'>
                <div className='user_files_container'>
                    <div className='user_files_list_header' id='faq_header'>
                        <div className='user_files_list_header_left'>                        
                            <label id='Models'>{TranslatedTexts.FAQ_Headline}</label>
                        </div>
                        <div className='user_files_list_header_right'>                        
                            <button onClick={() => this.state.goToMain()} className='back_button'>
                                <img src={BackButton}></img>
                                <label>{TranslatedTexts.Back_Button}</label>
                            </button>                      
                        </div>
                    </div>
                
                    <div className='faq_container'>
                        <div className="tab">
                            <input checked={this.state.checks[0]} onChange={() => this.changeCheck(0)} type="checkbox" id="rd1" name="rd"/>
                            
                            <label title={TranslatedTexts.FAQ_Button} className="tab-label" htmlFor="rd1">{TranslatedTexts.FAQ_Q1}</label>                                
                            
                            <div className="tab-content">
                            <label>{TranslatedTexts.FAQ_A1}</label>
                        <table className='faq_table'>
                            <tbody>
                            <tr className='table_header'>
                                <th>{TranslatedTexts.Table_File}</th>
                                <th>{TranslatedTexts.Table_Model}</th>
                                <th>{TranslatedTexts.Table_Material}</th>
                                <th>{TranslatedTexts.Table_Animation}</th>
                                <th>{TranslatedTexts.Table_Bone}</th>
                                <th>{TranslatedTexts.Table_LightType}</th>
                                <th>{TranslatedTexts.Table_LightSource}</th>
                            </tr>
                            <tr>
                                <td className='td_data' >FBX</td>
                                <td className='td_check'><img src={FAQCheck}></img></td>
                                <td className='td_check'><img src={FAQCheck}></img></td>
                                <td className='td_check'><img src={FAQCheck}></img></td>
                                <td className='td_x'><img src={FAQX}></img></td>
                                <td className='td_check'><img src={FAQCheck}></img></td>
                                <td className='td_x'><img src={FAQX}></img></td>
                            </tr>
                            <tr>
                                <td className='td_data'>OBJ</td>
                                <td className='td_check'><img src={FAQCheck}></img></td>
                                <td className='td_check'><img src={FAQCheck}></img></td>
                                <td className='td_x'><img src={FAQX}></img></td>
                                <td className='td_x'><img src={FAQX}></img></td>
                                <td className='td_check'><img src={FAQCheck}></img></td>
                                <td className='td_x'><img src={FAQX}></img></td>
                            </tr>
                            <tr>
                                <td className='td_data'>GLB/GLTF</td>
                                <td className='td_check'><img src={FAQCheck}></img></td>
                                <td className='td_check'><img src={FAQCheck}></img></td>
                                <td className='td_check'><img src={FAQCheck}></img></td>
                                <td className='td_x'><img src={FAQX}></img></td>
                                <td className='td_check'><img src={FAQCheck}></img></td>
                                <td className='td_x'><img src={FAQX}></img></td>
                            </tr>
                            <tr>
                                <td className='td_data'>VRML/WRL</td>
                                <td className='td_check'><img src={FAQCheck}></img></td>
                                <td className='td_check'><img src={FAQCheck}></img></td>
                                <td className='td_check'><img src={FAQCheck}></img></td>
                                <td className='td_x'><img src={FAQX}></img></td>
                                <td className='td_check'><img src={FAQCheck}></img></td>
                                <td className='td_x'><img src={FAQX}></img></td>
                            </tr>
                            <tr>
                                <td className='td_data'>DAE</td>
                                <td className='td_check'><img src={FAQCheck}></img></td>
                                <td className='td_warning'><img src={FAQWarning}></img></td>
                                <td className='td_check'><img src={FAQCheck}></img></td>
                                <td className='td_x'><img src={FAQX}></img></td>
                                <td className='td_check'><img src={FAQCheck}></img></td>
                                <td className='td_x'><img src={FAQX}></img></td>
                            </tr>
                            <tr>
                                <td className='td_data'>STL</td>
                                <td className='td_check'><img src={FAQCheck}></img></td>
                                <td className='td_x'><img src={FAQX}></img></td>
                                <td className='td_x'><img src={FAQX}></img></td>
                                <td className='td_x'><img src={FAQX}></img></td>
                                <td className='td_x'><img src={FAQX}></img></td>
                                <td className='td_x'><img src={FAQX}></img></td>
                            </tr>
                            <tr>
                                <td className='td_data'>DXF</td>
                                <td className='td_check'><img src={FAQCheck}></img></td>
                                <td className='td_x'><img src={FAQX}></img></td>
                                <td className='td_x'><img src={FAQX}></img></td>
                                <td className='td_x'><img src={FAQX}></img></td>
                                <td className='td_x'><img src={FAQX}></img></td>
                                <td className='td_x'><img src={FAQX}></img></td>
                            </tr>
                            <tr>
                                <td className='td_data'>STEP</td>
                                <td className='td_check'><img src={FAQCheck}></img></td>
                                <td className='td_check'><img src={FAQCheck}></img></td>
                                <td className='td_x'><img src={FAQX}></img></td>
                                <td className='td_x'><img src={FAQX}></img></td>
                                <td className='td_x'><img src={FAQX}></img></td>
                                <td className='td_x'><img src={FAQX}></img></td>
                            </tr>                            
                            </tbody>
                        </table>  
                        <label>{TranslatedTexts.FAQ_A1a}</label>                        
                        <label>{TranslatedTexts.FAQ_A1b}</label>
                            </div>
                        </div>
                        <div className="tab">                                
                            <input checked={this.state.checks[1]} onChange={() => this.changeCheck(1)} type="checkbox" id="rd2" name="rd"/>
                            <label title={TranslatedTexts.FAQ_Button} className="tab-label" htmlFor="rd2">{TranslatedTexts.FAQ_Q2}</label>
                            <div className="tab-content">
                                {TranslatedTexts.FAQ_A2}
                            </div>
                        </div>
                        <div className="tab">
                            <input checked={this.state.checks[2]} onChange={() => this.changeCheck(2)} type="checkbox" id="rd3" name="rd"/>
                            <label title={TranslatedTexts.FAQ_Button} className="tab-label" htmlFor="rd3">{TranslatedTexts.FAQ_Q3}</label>
                            <div className="tab-content">
                                {TranslatedTexts.FAQ_A3}
                            </div>
                        </div>
                        <div className="tab">
                            <input checked={this.state.checks[3]} onChange={() => this.changeCheck(3)} type="checkbox" id="rd4" name="rd"/>
                            <label title={TranslatedTexts.FAQ_Button} className="tab-label" htmlFor="rd4">{TranslatedTexts.FAQ_Q4}</label>
                            <div className="tab-content">
                                {TranslatedTexts.FAQ_A4}
                            </div>
                        </div>   
                        <div className="tab">
                            <input checked={this.state.checks[4]} onChange={() => this.changeCheck(4)} type="checkbox" id="rd5" name="rd"/>
                            <label title={TranslatedTexts.FAQ_Button} className="tab-label" htmlFor="rd5">{TranslatedTexts.FAQ_Q5}</label>
                            <div className="tab-content">
                                {TranslatedTexts.FAQ_A5}
                            </div>
                        </div>   
                        <div className="tab">
                            <input checked={this.state.checks[5]} onChange={() => this.changeCheck(5)} type="checkbox" id="rd6" name="rd"/>
                            <label title={TranslatedTexts.FAQ_Button} className="tab-label" htmlFor="rd6">{TranslatedTexts.FAQ_Q6}</label>
                            <div className="tab-content">
                                {TranslatedTexts.FAQ_A6}
                            </div>
                        </div>   
                        <div className="tab">
                            <input checked={this.state.checks[6]} onChange={() => this.changeCheck(6)} type="radio" id="rd7" name="rd"/>
                            <label title={TranslatedTexts.FAQ_Button} className="tab-label" htmlFor="rd7">{TranslatedTexts.FAQ_Q7}</label>
                            <div className="tab-content">
                                {TranslatedTexts.FAQ_A7}
                            </div>
                        </div>   
                        <div className="tab">
                            <input checked={this.state.checks[7]} onChange={() => this.changeCheck(7)} type="checkbox" id="rd8" name="rd"/>
                            <label title={TranslatedTexts.FAQ_Button} className="tab-label" htmlFor="rd8">{TranslatedTexts.FAQ_Q8}</label>
                            <div className="tab-content">
                                {TranslatedTexts.FAQ_A8}
                            </div>
                        </div>   
                        <div className="tab">
                            <input checked={this.state.checks[8]} onChange={() => this.changeCheck(8)} type="checkbox" id="rd9" name="rd"/>
                            <label title={TranslatedTexts.FAQ_Button}  className="tab-label" htmlFor="rd9">{TranslatedTexts.FAQ_Q9}</label>
                            <div className="tab-content">
                                {TranslatedTexts.FAQ_A9}
                            </div>
                        </div>
                        <div className="tab">
                            <input checked={this.state.checks[9]} onChange={() => this.changeCheck(9)} type="checkbox" id="rd10" name="rd"/>
                            <label title={TranslatedTexts.FAQ_Button} className="tab-label" htmlFor="rd10">{TranslatedTexts.FAQ_Q10}</label>
                            <div className="tab-content">
                                {TranslatedTexts.FAQ_A10}
                            </div>
                        </div>
                        <div className="tab">
                            <input checked={this.state.checks[10]} onChange={() => this.changeCheck(10)} type="checkbox" id="rd11" name="rd"/>
                            <label title={TranslatedTexts.FAQ_Button} className="tab-label" htmlFor="rd11">{TranslatedTexts.FAQ_Q11}</label>
                            <div className="tab-content">
                                {TranslatedTexts.FAQ_A11}
                            </div>
                        </div>
                        <div className="tab">
                            <input checked={this.state.checks[11]} onChange={() => this.changeCheck(11)} type="checkbox" id="rd12" name="rd"/>
                            <label title={TranslatedTexts.FAQ_Button} className="tab-label" htmlFor="rd12">{TranslatedTexts.FAQ_Q12}</label>
                            <div className="tab-content">
                                {TranslatedTexts.FAQ_A12}
                            </div>
                        </div>
                        <div className="tab">
                            <input checked={this.state.checks[12]} onChange={() => this.changeCheck(12)} type="checkbox" id="rd13" name="rd"/>
                            <label title={TranslatedTexts.FAQ_Button} className="tab-label" htmlFor="rd13">{TranslatedTexts.FAQ_Q13}</label>
                            <div className="tab-content">
                                {TranslatedTexts.FAQ_A13}
                            </div>
                        </div>
                        <div className="tab">
                            <input checked={this.state.checks[13]} onChange={() => this.changeCheck(13)} type="checkbox" id="rd14" name="rd"/>
                            <label title={TranslatedTexts.FAQ_Button} className="tab-label" htmlFor="rd14">{TranslatedTexts.FAQ_Q14}</label>
                            <div className="tab-content">
                                {TranslatedTexts.FAQ_A14}
                            </div>
                        </div>
                        <div className="tab">
                            <input checked={this.state.checks[14]} onChange={() => this.changeCheck(14)} type="checkbox" id="rd15" name="rd"/>
                            <label title={TranslatedTexts.FAQ_Button} className="tab-label" htmlFor="rd15">{TranslatedTexts.FAQ_Q15}</label>
                            <div className="tab-content">
                                {TranslatedTexts.FAQ_A15}
                            </div>
                        </div>
                        <div className="tab">
                            <input checked={this.state.checks[15]} onChange={() => this.changeCheck(15)} type="checkbox" id="rd16" name="rd"/>
                            <label title={TranslatedTexts.FAQ_Button} className="tab-label" htmlFor="rd16">{TranslatedTexts.FAQ_Q16}</label>
                            <div className="tab-content">
                                {TranslatedTexts.FAQ_A16}
                            </div>
                        </div>
                        <div className="tab">
                            <input checked={this.state.checks[16]} onChange={() => this.changeCheck(16)} type="checkbox" id="rd17" name="rd"/>
                            <label title={TranslatedTexts.FAQ_Button} className="tab-label" htmlFor="rd17">{TranslatedTexts.FAQ_Q17}</label>
                            <div className="tab-content">
                                {TranslatedTexts.FAQ_A17}
                            </div>
                        </div>
                        <div className="tab">
                            <input checked={this.state.checks[17]} onChange={() => this.changeCheck(17)} type="checkbox" id="rd18" name="rd"/>
                            <label title={TranslatedTexts.FAQ_Button} className="tab-label" htmlFor="rd18">{TranslatedTexts.FAQ_Q18}</label>
                            <div className="tab-content">
                                {TranslatedTexts.FAQ_A18}
                            </div>
                        </div>
                        <div className="tab">
                            <input checked={this.state.checks[18]} onChange={() => this.changeCheck(18)} type="checkbox" id="rd19" name="rd"/>
                            <label title={TranslatedTexts.FAQ_Button} className="tab-label" htmlFor="rd19">{TranslatedTexts.FAQ_Q19}</label>
                            <div className="tab-content">
                                {TranslatedTexts.FAQ_A19}
                            </div>
                        </div>
                        <div className="tab">
                            <input checked={this.state.checks[19]} onChange={() => this.changeCheck(19)} type="checkbox" id="rd20" name="rd"/>
                            <label title={TranslatedTexts.FAQ_Button} className="tab-label" htmlFor="rd20">{TranslatedTexts.FAQ_Q20}</label>
                            <div className="tab-content">
                                {TranslatedTexts.FAQ_A20}
                            </div>
                        </div>                                                    
                    </div>                                
                </div>                        
            </div>            
        )
    };
}
export default FAQ;
