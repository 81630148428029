import React from 'react';
import './VithroughPopup.less'
import { TranslatedTexts } from '../Locale/TranslatedTexts';

class VithroughPopup extends React.Component {

    modelviewerIframe = null;
    modelviewerBody = null;
    animationProgressChecker = null;
    conversionChecker = null;

    static defaultProps = {
        title: "",
        text: "",
        progressbarPercent: 0,
        type: "progressbar",
        onClickButton: function(){},

    };

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {
        const props = this.props;

        return(
        <div className='vithrough_popup'>
            {props.title != null && props.title != "" && <label className='vithrough_popup_title'>{props.title}</label>}
            <label className='vithrough_popup_text'>{props.text}</label>
            {props.type == "progressbar" && 
                <div className='vithrough_popup_content'>
                    <div id="vithrough_convert_progressbar">
                        <div id="convert_progressbar_bar" style={{width: props.progressbarPercent + "%"}}></div>
                    </div>
                    <label className='vithrough_popup_load_text'>{TranslatedTexts.ModelSettingsPromptLoadText + " " + props.progressbarPercent + "%"}</label>
                </div>}
            {props.type == "button" && 
                <div className='vithrough_popup_content'>
                    <button onClick={() => {props.onClickButton()}} title={props.title} className='vithrough_popup_button armodelviewer_button'>
                        {TranslatedTexts.ModelPromptConfirmText}
                    </button>
                </div>}
        </div>)
    }
}

export default VithroughPopup;
