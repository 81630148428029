import React from 'react';
import CloseIcon from '../../images/close_icon.png'
import PlayIcon from '../../images/play_icon.png'
import PauseIcon from '../../images/pause_icon.png'
import PolygonIcon from '../../images/polygon_icon.png'
import CopyIcon from '../../images/copyLink_icon.png'
import QRDownloadIcon from '../../images/qrDownload_icon.png';
import WarningTriangle from '../../images/warning_triangle.png';
import {QRCodeSVG} from 'qrcode.react';
import './ModelviewerPopup.less'
import { TranslatedTexts } from '../Locale/TranslatedTexts';
import { Uploader } from '../Uploader/Uploader';
import CheckboxWithLabel from '../CheckboxWithLabel/CheckboxWithLabel';
import VithroughPopup from '../VithroughPopup/VithroughPopup';
import { Link } from "react-router-dom";
import { json } from 'react-router';

class ModelviewerPopup extends React.Component {

    modelviewerIframe = null;
    modelviewerBody = null;
    animationProgressChecker = null;
    conversionChecker = null;

    static defaultProps = {
        onClose: function(){},
        modelviewerModel: {},
        canEdit: false,
        downloadQRCode: function(){},
        copyLink: function(){},
        onModelUpdate: function(){},
        companyID: "",
        modelInitialOpen: false,
        modelWarningSize: 80000000, //10mb
        appAccess: false,
    };

    constructor(props) {
        super(props);
        let model = this.createNewModelData(this.props.modelviewerModel);
        let modelTooBig = model.GltfSize > this.props.modelWarningSize || model.UsdzSize > this.props.modelWarningSize;

        this.state = {
            playing: false,
            modelviewerUrl: "",
            hasAnimation: false,
            progressBarWidth: 0,
            viewerBackgroundColors: ["#eeeeee","#cacacb","#3d3e3f"],
            categoriesOpenStatus: [false, false, false],
            converting: false,
            conversionState : 1,
            defaultUnit: "m",
            modelData: model,
            dbModelData: this.props.modelviewerModel,
            modelTooBig: modelTooBig,
            warningOpen: this.props.modelInitialOpen && modelTooBig,
            modelWarningSize: this.props.modelWarningSize, 
        };
    }

    createNewModelData(obj){
        let newObj = {};
        for (var key in obj){
            var value = obj[key];
            newObj[key] = obj[key];
          }
        
        return newObj;
    }

    componentDidMount(){
        const c = this.state;
        if(c.modelData){
            let modelTooBig = this.state.modelData.GltfSize > this.props.modelWarningSize || this.state.modelData.UsdzSize > this.props.modelWarningSize;
            c.modelTooBig = modelTooBig;
            c.warningOpen = this.props.modelInitialOpen && modelTooBig;
        }

        c.conversionState = 1;
        this.setState(c)
    }

    componentDidUpdate(prevProps){
        if (this.props.modelviewerModel != prevProps.modelviewerModel) {
            if (this.props.modelviewerModel != null && (prevProps.modelviewerModel != null ? this.props.modelviewerModel.ItemID != prevProps.modelviewerModel.ItemID : true))
            {                
                this.props.onModelUpdate(this.props.modelviewerModel.ItemID);
            }
            
            const modelData = this.createNewModelData(this.props.modelviewerModel);
            const link = this.getLink();
            
            const modelTooBig = modelData.GltfSize > this.props.modelWarningSize || modelData.UsdzSize > this.props.modelWarningSize;
            const warningOpen = this.props.modelInitialOpen && modelTooBig;

            this.setState({modelviewerUrl: link, modelData: modelData, dbModelData: this.props.modelviewerModel, modelTooBig: modelTooBig, warningOpen: warningOpen/*, warningMessage: warningMessage*/}, () => this.updateModelviewer());
          }
    }

    getLink(){
        let model = this.props.modelviewerModel;
        return model ? this.generateModelLink(model.ItemID) : "";
        //NOTE: BEFORE: return model ? this.props.companyID ? this.generateUserLink(this.props.companyID) : this.generateModelLink(model.ItemID) : "" 
        //-> I tested with different accounts whether the models still show and they are showing. Leaving this here just in case because I am not up-to-date on the whole company system
    }

    generateUserLink(id){
        if(id){
            if (window.config.host.cdn.includes("vithrough"))
          return "https://viewer.vithrough.com/?userID=" + id;
        else
          return window.config.host.cdn + "/" + this.props.realm + "/modelviewer/?userID=" + id;
        }else{
          return null
        }
    }

    generateModelLink(id){
        if(id){
            if (window.config.host.cdn.includes("vithrough"))
          return "https://viewer.vithrough.com/?id=" + id;
        else
          return window.config.host.cdn + "/" + this.props.realm + "/modelviewer/?id=" + id;
        }else{
          return null
        }
    }

    closePopup(e){
        e.preventDefault();
        this.setState({converting: false, conversionState: 1})
        clearInterval(this.conversionChecker);
        this.conversionChecker = null;
        this.props.onClose();
        e.stopPropagation();
    }

    formatBytes(bytes, decimals = 2) {
        if (!+bytes) return '0 Bytes'
    
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
    
        const i = Math.floor(Math.log(bytes) / Math.log(k))
    
        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }

    updateFileScaleValue(e, value){
        if(e && e.target){
            let newScale = value ? value : e.target.value;
            this.setModelData(newScale/100, "Scale");   
        }
        e.stopPropagation();
      }

    updateModelDataCheckboxWithLabel(e, param, value){
        if(e.target){
            this.setModelData(value, param);  
        }
    }

    updateModelDataCheckbox(e, param){
        if(e.target){
            this.updateModelData(e.target.checked ? 1 : 0, param);
        }
    }

    setModelData(value, param){
        const c = this.state.modelData;
        c[param] = value;
        this.setState({modelData: c});
    }

    updateModelData(value, param){
        this.setModelData(value, param);
        let model = this.state.modelData;
        Uploader.updateJobParameter(model.ItemID, model.Animate, model.Position, model.BackgroundColor).then(() => {
             this.updateModelviewer();
             this.props.onModelUpdate(model.ItemID);
        })
    }

    reconvertModel(){
        let model = this.state.modelData;
        this.activateConversionChecker();
        this.setState({converting: true})
        Uploader.reconvertModel(model.ItemID, model.UpAxis, model.ForwardAxis, model.BaseUnit, model.Scale).then(() => {
            this.updateModelviewer();
            this.props.onModelUpdate(model.ItemID);
        })
    }

    updateModelviewer(){
        this.modelviewerIframe = document.getElementById("modelviewer_iframe");        
        this.modelviewerBody = this.modelviewerIframe ? this.modelviewerIframe.contentWindow : null;        
        let model = this.props.modelviewerModel;
        
        if(this.modelviewerBody){            
            try{            
                 //NOTE: this causes a cross-site error in localhost! Way to fix this was not found yet     
                if(this.modelviewerBody.loadModel != null && (typeof this.modelviewerBody.loadModel === "function")){
                    this.modelviewerBody.loadModel(model.ItemID);            
                    this.setState({hasAnimation: this.modelviewerBody.hasAnimation ? this.modelviewerBody.hasAnimation() : false}, this.togglePlaying());
                }
            }catch{
                //console.error("Function call in localhost not possible due to cross-side issues")
                this.setState({hasAnimation: false});
            }
        }
    }

    checkModelAnimationProgress(){
        let duration = this.modelviewerBody.getDuration();        
        let currentTime = this.modelviewerBody.getCurrentTime();    
        let percent = duration/currentTime;        
        this.setState({progressBarWidth: percent})
    }

    togglePlaying(){
        try{
            if(this.modelviewerBody && this.state.hasAnimation){
                if(this.modelviewerBody.getPaused()){
                    this.modelviewerBody.togglePlayAnimation(true);
                }else{
                    clearInterval(this.animationProgressChecker)
                    this.modelviewerBody.togglePlayAnimation(false);
                }
            }
        }catch{
            //console.error("Function call in localhost not possible due to cross-side issues")
            this.setState({hasAnimation: false});
        }
    }

    activateConversionChecker(){
        clearInterval(this.conversionChecker);
        this.conversionChecker = setInterval(() => {
            this.checkFileState(this.state.modelData);
          }, 1000);
    }

    setCategoryStatus(idx, active){
        let newActive = this.state.categoriesOpenStatus;
        newActive[idx] = active;
        this.setState({categoriesOpenStatus: newActive})
    }

    
    checkFileState(file)
    {  
        if(file && file.ItemID){
            Uploader.getJobState(file.ItemID, this.props.companyID)
            .then((resp) => {
                if(resp && resp.Job){                    
                    const st = resp.Job["State"];
                    if(st == 1){
                        clearInterval(this.conversionChecker);
                        this.setState({converting: false})
                        this.conversionChecker = null;
                        this.props.onModelUpdate(file.ItemID, true);
                    }

                    this.setState({conversionState: resp.Job["State"]})
                }
            })
            .catch((err) => {})
        }   
    }

    generateWarningMessage(data, appAccess){
        if(data != null){
            let rapid = data.Rapid;
            if(rapid){
                if(appAccess){
                    //has rapid and app
                    return {title: TranslatedTexts.ModelTooBigRapidAppTitle, text: TranslatedTexts.ModelTooBigRapidAppText}
                }else{
                    // has rapid only
                    return {title: TranslatedTexts.ModelTooBigRapidTitle, text: TranslatedTexts.ModelTooBigRapidText}
                }
            }else if(appAccess){
                //has app only
                return {title: TranslatedTexts.ModelTooBigAppTitle, text: TranslatedTexts.ModelTooBigAppText}
            }
        }

        //has nothing
        return {title: TranslatedTexts.ModelTooBigTitle, text: TranslatedTexts.ModelTooBigText}
    }

    render() {
        if(this.props.modelviewerModel && this.state.modelviewerUrl){
            const model = this.state.modelData;
            const dbModel = this.state.dbModelData;
            const modelUrl = this.state.modelviewerUrl;
            const bColors = this.state.viewerBackgroundColors;
            const warningMessage = this.generateWarningMessage(model, this.props.appAccess);

            return (
                <div className="modelviewer_popup">
                    <div className='modelviewer_popup_overlay' >
                        <div className='modelviewer_popup_container'>
                            <div className='modelviewer_popup_header'>
                                <div className='modelviewer_popup_title'>
                                    <div className='user_list_circle'/>
                                    <label className='bold'>{model.Description}</label>
                                </div>
                                <button onClick={(e) => this.closePopup(e)}>
                                    <img src={CloseIcon}/>
                                </button>
                            </div>
                            <div className='modelviewer_popup_body'>
                                <div className='modelviewer_popup_model'>
                                    <div className='modelviewer_popup_model_body'>
                                        <iframe id='modelviewer_iframe' src={modelUrl.replace("modelviewer","modelviewer_preview").replace("viewer.vithrough.com/", "portal.vithrough.com/modelviewer_preview/")}></iframe>
                                    </div>
                                    <div className='modelviewer_popup_model_colorpicker'>
                                        <button disabled={!this.props.canEdit} style={{backgroundColor: bColors[0], borderColor: model.BackgroundColor == bColors[0] ? "#638484" : "transparent"}} onClick={() => this.updateModelData(bColors[0],"BackgroundColor")}></button>
                                        <button disabled={!this.props.canEdit} style={{backgroundColor: bColors[1], borderColor: model.BackgroundColor == bColors[1] ? "#638484" : "transparent"}} onClick={() => this.updateModelData(bColors[1],"BackgroundColor")}></button>
                                        <button disabled={!this.props.canEdit} style={{backgroundColor: bColors[2], borderColor: model.BackgroundColor == bColors[2] ? "#638484" : "transparent"}} onClick={() => this.updateModelData(bColors[2],"BackgroundColor")}></button>
                                    </div>
                                    {this.state.hasAnimation && false && 
                                    <div className='modelviewer_popup_model_footer'>
                                        <button onClick={() => this.togglePlaying()}><img src={this.state.playing ? PauseIcon : PlayIcon}/></button>
                                        <div id="progressbar">
                                            <div id="progressbar_bar" style={{width: this.state.progressBarWidth + "%"}}></div>
                                        </div>
                                    </div>}
                                    {this.state.modelTooBig && 
                                        <Link title={TranslatedTexts.Warning} to="/faq" className='modelviewer_popup_warning'>
                                            <img src={WarningTriangle}/>
                                            <label>{TranslatedTexts.Warning}</label>
                                        </Link>}
                                    {this.state.converting && 
                                        <div className='modelviewer_popup_prompt'>
                                            <VithroughPopup 
                                                title={model.Description + " " + TranslatedTexts.ModelSettingsPromptTitle}
                                                text ={TranslatedTexts.ModelSettingsPromptText}
                                                progressbarPercent = {Math.round((this.state.conversionState/6) * 100)}
                                                type= "progressbar"/>
                                        </div>}
                                    {this.state.warningOpen &&
                                        <div className='modelviewer_popup_prompt'>
                                            <VithroughPopup
                                            title={warningMessage ? warningMessage.title : TranslatedTexts.ModelTooBigTitle}
                                            text ={warningMessage ? warningMessage.text :TranslatedTexts.ModelTooBigText}
                                            type= "button"
                                            onClickButton={() => this.setState({warningOpen: false})}/>
                                        </div>}
                                </div>
                                <div className='modelviewer_popup_details'>
                                    <div className='modelviewer_popup_details_category not_adjustable'>
                                        <div className={this.state.categoriesOpenStatus[0] ? 'category_header_open' : 'category_header_closed'} onClick={() => this.setCategoryStatus(0, !this.state.categoriesOpenStatus[0])}>
                                            <label className='bold'>{TranslatedTexts.ModelSettingsData}</label>
                                            <button className={this.state.categoriesOpenStatus[0] ? "polygon_opened" : "polygon_closed"}><img src={PolygonIcon}/></button>
                                        </div>
                                        {this.state.categoriesOpenStatus[0] &&<div className='modelviewer_popup_details_content'>
                                            <div className='modelviewer_popup_details_element'>
                                                <label className='bold'>{model.Description}</label>
                                            </div>
                                            <div className='modelviewer_popup_details_element'>
                                                <label className='bold'>{TranslatedTexts.ModelSettingsSize}</label>
                                                <label>{TranslatedTexts.ModelSettingsSizeRaw + this.formatBytes(model.RawSize)}</label>
                                                <label>{TranslatedTexts.ModelSettingsSizeGltf + this.formatBytes(model.GltfSize)}</label>
                                                <label>{TranslatedTexts.ModelSettingsSizeUsdz + this.formatBytes(model.UsdzSize)}</label>
                                            </div>
                                            <div className='modelviewer_popup_details_element'>
                                                <label className='bold'>{TranslatedTexts.ModelSettingsDataType}</label>
                                                <label>{model.FileType ? model.FileType.replace('.','') : ""}</label>
                                            </div>
                                            <div className='modelviewer_popup_details_element'>
                                                <label className='bold'>{TranslatedTexts.ModelSettingsLastChange}</label>
                                                <label>{model.Updated}</label>
                                            </div>
                                            <div className='modelviewer_popup_details_element'>
                                                <label className='bold'>{TranslatedTexts.ModelSettingsScaleInPercent}</label>
                                                <label>{Math.round(dbModel.Scale * 100) + "%"}</label>
                                            </div>
                                            <div className='modelviewer_popup_details_element'>
                                                <label className='bold'>{TranslatedTexts.ModelSettingsModelScale}</label>
                                                <label>{model.Modelsize ? model.Modelsize : "-"}</label>
                                            </div>
                                        </div>}
                                    </div>
                                    <div className={this.props.canEdit ? 'modelviewer_popup_details_category' : 'modelviewer_popup_details_category not_adjustable'}>
                                        <div className={this.state.categoriesOpenStatus[1] ? 'category_header_open' : 'category_header_closed'} onClick={() => this.setCategoryStatus(1, !this.state.categoriesOpenStatus[1])}>
                                            <label className='bold'>{TranslatedTexts.ModelSettingsOptions}</label>
                                            <button className={this.state.categoriesOpenStatus[1] ? "polygon_opened" : "polygon_closed"}><img src={PolygonIcon}/></button>
                                        </div>
                                        {this.state.categoriesOpenStatus[1] &&<div className='modelviewer_popup_details_content'>
                                            <div className='modelviewer_popup_details_element'>                                        
                                                <label className='bold'>{TranslatedTexts.ModelSettingsPlacement}</label>                                                                  
                                            </div>
                                            <div className='modelviewer_popup_details_element'>
                                                <div className='modelviewer_popup_details_subelement'>
                                                    <div className='modelposition_container'>
                                                        <input disabled={!this.props.canEdit} id="modelposition" type='checkbox' className='hidden' checked={model.Position} onChange={(e) => this.updateModelDataCheckbox(e, "Position")}/>
                                                        <label className={this.props.canEdit ? 'inputLabel_left' : 'inputLabel_left disabled'} htmlFor="modelposition">{TranslatedTexts.ModelSettingsPlacementWall}</label>
                                                    </div>
                                                    <div>
                                                        <input disabled={!this.props.canEdit} id="modelposition" type='checkbox' className='hidden' checked={!model.Position} onChange={(e) => this.updateModelDataCheckbox(e, "Position")}/>
                                                        <label className={this.props.canEdit ? 'inputLabel_right' : 'inputLabel_right disabled'} htmlFor="modelposition">{TranslatedTexts.ModelSettingsPlacementFloor}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            {/*
                                            <div className='modelviewer_popup_details_element_horizontal'>
                                                <label className='bold'>{TranslatedTexts.ModelSettingsAnimation}</label>
                                                <label className="switch">
                                                    <input disabled={!this.props.canEdit} checked={model.Animate} onChange={(e) => this.updateModelDataCheckbox(e, "Animate")} type="checkbox"></input>
                                                    <span className={this.props.canEdit ? "slider round" : "slider round slider_disabled"}></span>
                                                </label>
                                            </div>
                                            */}
                                        </div>}
                                    </div>
                                    <div className={this.props.canEdit ? 'modelviewer_popup_details_category' : 'modelviewer_popup_details_category not_adjustable'}>
                                        <div className={this.state.categoriesOpenStatus[2] ? 'category_header_open' : 'category_header_closed'} onClick={() => this.setCategoryStatus(2, !this.state.categoriesOpenStatus[2])}>
                                            <label className='bold'>{TranslatedTexts.ModelSettingsSettings}</label>
                                            <button className={this.state.categoriesOpenStatus[2] ? "polygon_opened" : "polygon_closed"}><img src={PolygonIcon}/></button>
                                        </div>
                                        {this.state.categoriesOpenStatus[2] &&<div className='modelviewer_popup_details_content'>
                                            <div className='modelviewer_popup_details_element'>
                                                <div title={TranslatedTexts.ModelSettingsAxis_Help}><label className='bold'>{TranslatedTexts.ModelSettingsAxis}</label></div>
                                                <CheckboxWithLabel 
                                                    disabled={!this.props.canEdit} 
                                                    labelContent="Y"
                                                    value={(!model["UpAxis"])} 
                                                    checked={(!model["UpAxis"])} 
                                                    onClick={(e, newChecked) => {this.updateModelDataCheckboxWithLabel(e, "UpAxis", newChecked ? 0 : 1)}} 
                                                />
                                                <CheckboxWithLabel 
                                                    disabled={!this.props.canEdit} 
                                                    labelContent="Z" 
                                                    value={(model["UpAxis"])} 
                                                    checked={(model["UpAxis"])} 
                                                    onClick={(e, newChecked) => {this.updateModelDataCheckboxWithLabel(e, "UpAxis", newChecked ? 1 : 0)}} 
                                                />
                                            </div>
                                            <div className='modelviewer_popup_details_element'>
                                                <div title={TranslatedTexts.FilePickerFwAxisTitle}><label className='bold'>{TranslatedTexts.FilePickerFwAxisTitle}</label></div>
                                                <CheckboxWithLabel 
                                                    disabled={!this.props.canEdit} 
                                                    labelContent={model["UpAxis"] == 0 ? "-Z" : "-Y"}
                                                    value={(!model["ForwardAxis"])} 
                                                    checked={(!model["ForwardAxis"])} 
                                                    onClick={(e, newChecked) => {this.updateModelDataCheckboxWithLabel(e, "ForwardAxis", newChecked ? 0 : 1)}} 
                                                />
                                                <CheckboxWithLabel 
                                                    disabled={!this.props.canEdit} 
                                                    labelContent={model["UpAxis"] == 0 ? "Z" : "Y"}
                                                    value={(model["ForwardAxis"])} 
                                                    checked={(model["ForwardAxis"])} 
                                                    onClick={(e, newChecked) => {this.updateModelDataCheckboxWithLabel(e, "ForwardAxis", newChecked ? 1 : 0)}} 
                                                />
                                            </div>
                                            
                                            <div className='modelviewer_popup_details_element'>
                                                <div title={TranslatedTexts.FileScale_Help}><label className='bold'>{TranslatedTexts.ModelSettingsUnit}</label></div>
                                                <CheckboxWithLabel 
                                                    disabled={!this.props.canEdit}  
                                                    labelContent={TranslatedTexts.ModelSettingsUnitMM} 
                                                    value={(model["BaseUnit"]) == "mm"} 
                                                    checked={(model["BaseUnit"] == "mm")} 
                                                    onClick={(e, newChecked) => {this.updateModelDataCheckboxWithLabel(e, "BaseUnit", "mm")}} 
                                                />
                                                <CheckboxWithLabel 
                                                    disabled={!this.props.canEdit} 
                                                    labelContent={TranslatedTexts.ModelSettingsUnitCM} 
                                                    value={(model["BaseUnit"] == "cm")} 
                                                    checked={(model["BaseUnit"] == "cm")} 
                                                    onClick={(e, newChecked) => {this.updateModelDataCheckboxWithLabel(e, "BaseUnit", "cm")}} 
                                                />
                                                <CheckboxWithLabel 
                                                    disabled={!this.props.canEdit} 
                                                    labelContent={TranslatedTexts.ModelSettingsUnitM} 
                                                    value={(model["BaseUnit"] == "m" || model["BaseUnit"] == null)} //default value 
                                                    checked={(model["BaseUnit"] == "m" || model["BaseUnit"] == null)} 
                                                    onClick={(e, newChecked) => {this.updateModelDataCheckboxWithLabel(e, "BaseUnit", "m")}}
                                                />
                                            </div>
                                            
                                            <div className='modelviewer_popup_details_element'>
                                                <div title={TranslatedTexts.FileScale_Help}><label className='bold'>{TranslatedTexts.FileScale}</label></div>
                                                <CheckboxWithLabel 
                                                    disabled={!this.props.canEdit} 
                                                    labelOnClick={(e) => this.updateFileScaleValue(e, 100)} 
                                                    labelContent={TranslatedTexts.FileScaleOriginal} 
                                                    value={(model["Scale"] == 1.0)} 
                                                    checked={(model["Scale"] == 1.0)} 
                                                    onClick={(e) => {this.updateFileScaleValue(e, 100)}} 
                                                />
                                                <div className={this.props.canEdit ? 'checkbox_with_label' : 'checkbox_with_label checkbox_with_label_disabled'}>
                                                    <div className='checkbox_container'>
                                                        <input disabled={!this.props.canEdit} ref={this.originalScaleCheckbox} type="checkbox" value={(model["Scale"] != 1.0)} checked={(model["Scale"] != 1.0)} onClick={(e) => {e.stopPropagation()}} onChange={() => {}}></input>
                                                        <span className="checkmark"></span>
                                                    </div>
                                                    <label className='file_data_element_option'>{TranslatedTexts.FileScaleCustom}(%): </label>  
                                                    <input disabled={!this.props.canEdit} style={{width: '3em'}} type="number" value={Math.round(model["Scale"]*100).toString()} min="1" step="1" pattern="\d*" onBlur={() => this.setState({adjustingScale: false})} onClick={(e) => {e.stopPropagation();}} onChange={(e) => {this.updateFileScaleValue(e)}}></input>  
                                                </div>
                                            </div>
                                            {
                                                this.props.canEdit
                                                &&
                                                <div className='modelviewer_popup_details_element'>
                                                    <label className='conversion_hint'>{TranslatedTexts.ModelSettingsConversionNote}</label>
                                                </div>
                                            }     
                                            {
                                                this.props.canEdit
                                                &&                                       
                                            <div className='modelviewer_popup_details_element'>
                                                <button className='armodelviewer_button conversion_button' disabled={false} title={TranslatedTexts.ModelSettingsConvert} onClick={(e) => this.reconvertModel()}>
                                                    {TranslatedTexts.ModelSettingsConvert}
                                                </button>
                                            </div>
                                            }
                                        </div>}
                                    </div>
                                   
                                    <div className='modelviewer_popup_details_element_horizontal nopadding'>
                                        <div className='modelviewer_popup_details_element nopadding'>
                                            <label className='bold'>{TranslatedTexts.ModelSettingsQRCode}</label>
                                            <button title={TranslatedTexts.Download} className='smallButton' onClick={() => this.props.downloadQRCode(this.state.modelviewerUrl,model.Description+"_qr.png")}><img src={QRDownloadIcon}/></button>
                                            <div className='qr_code_container'>
                                                <QRCodeSVG className='qr_code' value={this.state.modelviewerUrl} />
                                            </div>
                                        </div>
                                        <div className='modelviewer_popup_details_element nopadding'>
                                            <label className='bold'>{TranslatedTexts.ModelSettingsLink}</label>
                                            <button title={TranslatedTexts.ModelLinkTitle} className='smallButton' onClick={() => this.props.copyLink(this.state.modelviewerUrl)}><img src={CopyIcon}/></button>                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }else{
            return null;
        }
    }
}

export default ModelviewerPopup;
