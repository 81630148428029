
import React, { Component } from 'react';
import FooterComponents from '../src/configurations/armodelviewer/components/FooterComponents' /*'{{CONFIGURATION_FOOTER_COMPONENTS}}'*/;
import OverlayComponents from '../src/configurations/armodelviewer/components/OverlayComponents'/*'{{CONFIGURATION_OVERLAY_COMPONENTS}}'*/;
import {BrowserRouter, useLocation, useNavigate, useParams} from "react-router-dom";


class App extends Component {

  constructor(props){
    super(props);    
  }
  state = {
  };

  

  render() {
    return (
        <div className="app" > {/*onContextMenu={this.handleContextMenu} onMouseDown={this.handleMouseDown} */}
            <div id="fullscreen-root" className="fullscreen-root">                
                <BrowserRouter basename="/">
                  <OverlayComponents className="overlay-components"/>
                
                </BrowserRouter>
            </div>
            <FooterComponents className="footer-components" />
        </div>
    );
  }
}



export default App;
