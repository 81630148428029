import React from 'react';
import BinIcon from '../../images/bin_icon.png'
import PlusIcon from '../../images/+_icon.png'
import { TranslatedTexts } from '../Locale/TranslatedTexts';
import Dialog from '../Dialog/Dialog';
import { AdminHandler } from '../AdminHandler/AdminHandler';
import SearchBar from '../SearchBar/SearchBar';
import Checkmark from '../../images/faq_check.png'

class UserList extends React.Component {
    usersSortTags = [
        {title: TranslatedTexts.SortTagName, 
            function: 
            function(elements){ 
                elements.sort(function(a, b){
                if(a.UserName < b.UserName) { return -1; }
                if(a.UserName > b.UserName) { return 1; }
                return 0;
            })
            }},
        {title: TranslatedTexts.SortTagUsersCompanyName, 
            function: 
            (elements) => { 
                elements.sort(function(a, b){
                const companyNameA = this.getCompanyName(a.Company);
                const companyNameB = this.getCompanyName(b.Company);
                if(companyNameA < companyNameB) { return -1; }
                if(companyNameA > companyNameB) { return 1; }
                return 0;
            }.bind(this))
            }},
        {title: TranslatedTexts.SortTagUsersRole, 
            function: 
            (elements) => { 
                elements.sort(function(a, b){
                return a.UserRole - b.UserRole;
            })
            }},
        ]

    static defaultProps = {
      realm: null,
      companyID: null,
      companies:[],
      onClickTableRow: function(){},
    };

    constructor(props) {
        super(props);

        this.state = {
          users: [],
          companies: [],
          addingUser: false,
          addingCompany: false,
          tempCompanyName: "",
          tempCompanyLimit: 0,
          tempUserEmail: "",
          tempUserName: "",
          tempUserCompany: "",
          tempUserlocale: "de",
          tempUserRole: "1", 
          usersSearchOptions: {sortTag: this.usersSortTags[0], filterTag: null, inverted: false},
        };

    }

    componentDidMount(){
      this.getUsers(this.props.companies);      
    }

    componentDidUpdate(prevProps){
      if (prevProps.companies !== this.props.companies) {      
        this.getUsers(this.props.companies);
      }
    }

    getCompanyName(companyGuid){
      var company = this.props.companies.filter(company => company.CompanyGuid == companyGuid)[0]
      var companyName = "";
      if (company != null){
        companyName = this.props.companies.filter(company => company.CompanyGuid == companyGuid)[0].CompanyName; 
      }
    
      return companyName;
    }

    getUsers(newCompanies){ //TODO
        const self = this;      
        AdminHandler.getAllUsers("asd")
        .then((res) => {
            if (newCompanies){
                res.data.sort((a,b) => {
                    if (a == null || b == null) return 0;
                
                    var companyNameA = newCompanies.filter(company => company.CompanyGuid == a.Company)[0]?.CompanyName;
                    var companyNameB = newCompanies.filter(company => company.CompanyGuid == b.Company)[0]?.CompanyName;
                    
                    if (companyNameA == companyNameB){
                        return a.UserName > b.UserName ? 1 : -1;
                    }else{
                        return companyNameA > companyNameB ? 1 : -1;
                    }
                });  
            }
            
            self.setState({users: res.data});
        })
        .catch((err) => {})
    }

    generateUserLink(id){
        if(id){
            if (window.config.host.cdn.includes("vithrough"))
            return "https://viewer.vithrough.com/?userID=" + id;
            else
            return window.config.host.cdn + "/" + this.props.realm + "/modelviewer/?userID=" + id;
        }else{
            return null
        }
    }

    updateUserRole(newVal, userMail)
    {
        var newUsers = this.state.users;
        newUsers.find((e) => e.UserEmail == userMail).UserRole = newVal;
        this.setState({users: newUsers});      
        AdminHandler.updateUserRole(newVal, userMail)
        .then((res) => {})
        .catch((err) =>{})
    }

    getUserTable(){
        var elements = [];
        let usersToRender = this.state.users;
        const searchOptions = this.state.usersSearchOptions;
        if(searchOptions){
            if(searchOptions.sortTag){
                // --> sort companies
                searchOptions.sortTag.function(usersToRender);
                if(searchOptions.inverted){
                    usersToRender.reverse(); 
                }
            }

            if(searchOptions.filterTag){
                // --> filter companies
                usersToRender = searchOptions.filterTag.function(usersToRender, searchOptions.filterTag.searchText);
            }
        }

        usersToRender.map((elem, indx) => {
            var company = this.props.companies.filter(company => company.CompanyGuid == elem.Company)[0]
            var companyName = "";
            if (company != null){
                companyName = this.props.companies.filter(company => company.CompanyGuid == elem.Company)[0].CompanyName;          
                elements.push(
                    <tr className='TableRow' onClick={() => this.props.onClickTableRow(indx)} key={indx}>
                        <td>{elem.UserName}</td>
                        <td>{elem.UserEmail}</td>
                        <td>{elem.UserLocale}</td>
                        <td>{companyName}</td>
                        <td>
                            <select value={elem.UserRole} onChange={(e) => this.updateUserRole(e.target.value, elem.UserEmail)} title='UserRole'>
                            <option value="1">Admin</option>
                            <option value="2">Editor</option>
                            <option value="3">User</option>
                            </select>
                        </td>
                        <td>
                            <button className="armodelviewer_button" title={TranslatedTexts.RemUser} style={{fontSize: '0.8em', marginRight: '1em'}} onClick={() => this.remUser(indx)}><img src={BinIcon}/></button>
                        </td>                                                    
                    </tr>
                )
            }
        });

        return elements;
    }

    getCompanyOptions(){
        var elements = [];
        this.props.companies.map((elem, indx) => 
            (          
            elements.push(
                <option>{elem.CompanyName}</option>)
            ));
        return elements;        
    }

    addUser(){
        const self = this;
        var companyId = this.getCompanyIdFromName(this.state.tempUserCompany)

        AdminHandler.addUser(this.state.tempUserName, this.state.tempUserEmail, this.state.tempUserlocale, this.state.tempUserRole, companyId, this.state.tempUserCompany)
        .then((res) => {
            self.resetInputFields();
            self.getUsers(this.props.companies);
        })
        .catch((err) =>{})
    }

    resetInputFields(){
        this.setState({addingUser: false, addingCompany: false, tempCompanyName: "",tempCompanyLimit: 0, tempUserEmail: "", tempUserName: "", tempUserCompany: "",tempUserlocale: "de", tempUserRole: "1"});
    }

    getCompanyIdFromName(companyName)
    {
        var companyid = "";
        this.props.companies.forEach(company => {
            if (company.CompanyName == companyName)
            {          
                companyid = company.CompanyGuid;          
            }
        });

        return companyid;
    }

    remUser(useridx){
        const self = this;
        var username = this.state.users[useridx].UserEmail;
        Dialog.Alert({
                title: TranslatedTexts.RemUser + " " + username,
                description: TranslatedTexts.RemUserSure,
                options: [
                    {label: TranslatedTexts.Delete, className:"dangerConfirm"},
                    {label: TranslatedTexts.Cancel, className:""},
                ],
                panelOptions: {className: "messebau"}
            }).then((optionChosen) => {
            var userid = this.state.users[useridx].UserGuid;
            if (optionChosen == 0) {
                AdminHandler.remUser("userid", userid)
                .then((res) => {              
                    self.setState({addingUser: false});
                    self.getUsers(this.props.companies);
                })
                .catch((err) =>
                {
                    self.getUsers(this.props.companies);
                })     
            }
        });       
    }

    render() {
        return (
        <div className='user_files_container'>
            <div className='user_files_list_header'>
                <div className='user_files_list_header_left'>
                    <div id='user_list_circle' className='user_list_circle'/>
                    <label id='Models'>{TranslatedTexts.Users}</label>                      
                </div>
            </div>
            <div className='user_files_list_container'>
                <SearchBar
                    onNewSearchOptions={(options) => {this.setState({usersSearchOptions: options})}}
                    sortTags={this.usersSortTags}
                    filterTags={[
                        {title: TranslatedTexts.FilterTagName,  
                            function: 
                            function(elements, filterText){ 
                                return elements.filter((elem) => {
                                if(filterText == null || filterText == ""){
                                    return true; //if there is nothing to filter against
                                }
                                return elem && elem.UserName && filterText && elem.UserName.toLowerCase().indexOf(filterText.toLowerCase()) >= 0;
                            })
                        }},
                    ]}
                />
                <div className='user_files_list'>
                    <table style={{color: "rgba(255, 255, 255, 0.87)", width: "80vw"}}>
                        <thead style={{textAlign: "left"}}>
                            <tr>
                            <th style={{color: "rgba(255, 255, 255, 0.5)"}}>Name</th>                            
                            <th style={{color: "rgba(255, 255, 255, 0.5)"}}>Email</th>
                            <th style={{color: "rgba(255, 255, 255, 0.5)"}}>{TranslatedTexts.Language}</th>
                            <th style={{color: "rgba(255, 255, 255, 0.5)"}}>Company</th>
                            <th style={{color: "rgba(255, 255, 255, 0.5)"}}>Role</th>
                            <th>
                            { !this.state.addingUser && 
                            <button className="armodelviewer_button admin_buttons" title={TranslatedTexts.AddUser} style={{fontSize: '0.8em', marginRight: '1em'}} onClick={() => this.setState({addingUser: true})}><img src={PlusIcon}></img></button>}                          
                            </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.addingUser &&
                                <tr key="-1">
                                <td>
                                <input 
                                    type='text'
                                    value={this.state.tempUserName}
                                    onChange={(e) => this.setState({tempUserName: e.target.value})}
                                    >
                                </input>                                                                                    
                                </td>
                                <td>
                                <input 
                                type='text'                                                                                    
                                value={this.state.tempUserEmail}
                                onChange={(e) => this.setState({tempUserEmail: e.target.value})}
                                >                            
                                </input>
                                </td>
                                <td>
                                    <select
                                    value={this.state.tempUserlocale}
                                    onChange={(e) => this.setState({tempUserlocale: e.target.value})}
                                    >
                                    <option>de</option>
                                    <option>en</option>
                                    </select>                                  
                                </td>                            
                                <td>
                                    <select
                                    value={this.state.tempUserCompany}
                                    onChange={(e) => this.setState({tempUserCompany: e.target.value})}
                                    >
                                    <option></option>
                                    {
                                    this.getCompanyOptions()
                                    }
                                    </select>                                  
                                </td>
                                <td>
                                    <select
                                    value={this.state.tempUserRole}
                                    onChange={(e) => this.setState({tempUserRole: e.target.value})}
                                    >
                                    <option value="1">Admin</option>
                                    <option value="2">Editor</option>
                                    <option value="3">User</option>
                                    </select>                                  
                                </td>
                                <td>
                                    <button title={TranslatedTexts.AddUser} onClick={() => this.addUser()} style={{fontSize: '0.8em', marginRight: '1em'}} className="armodelviewer_button"><img style={{height: "16px", width: "16px"}} src={Checkmark}></img></button><button title={TranslatedTexts.Cancel} onClick={() => this.resetInputFields()} style={{fontSize: '0.8em', marginRight: '1em'}} className="armodelviewer_button"><img  style={{height: "16px", width: "16px", transform: "rotate(45deg)"}} src={PlusIcon}></img></button>
                                </td>
                                </tr>
                            }
                            {this.getUserTable()}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>);
    }
}

export default UserList;
